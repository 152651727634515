import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from "@mantine/hooks";
import {
  createStyles,
  Paper,
  Text,
  Title,
  Button,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { useEffect } from "react";
import { useModals } from "@mantine/modals";

const useStyles = createStyles((_theme, _params, getRef) => ({
  controls: {
    ref: getRef("controls"),
    transition: "opacity 150ms ease",
    opacity: 0,
  },

  root: {
    "&:hover": {
      [`& .${getRef("controls")}`]: {
        opacity: 1,
      },
    },
  },
  card: {
    height: 250,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
}));

interface CardProps {
  image: string;
  title: string;
  category: string;
}

function Card({ image, title, category }: CardProps) {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const modals = useModals();
  const openImageModal = (url) => {
    const id = modals.openModal({
      title: "Image",
      classNames: {
        title: "f3 b",
      },
      size: `${mobile ? "100%" : "40%"}`,
      children: (
        <Image
          height={mobile ? "100%" : 400}
          width={mobile ? "100%" : 400}
          src={url}
        />
      ),
    });
  };
  return (
    <Paper
      onClick={() => {
        openImageModal(image);
      }}
      shadow="md"
      p="xl"
      radius="md"
      styles={{
        root: {
          width: "100vh",
        },
      }}
      sx={{ backgroundImage: `url(${image})` }}
      className={classes.card}
    ></Paper>
  );
}

export function CardsCarousel({ data }: any) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const { classes } = useStyles();
  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <Card {...item} />
    </Carousel.Slide>
  ));
  return (
    <Carousel
      style={{
        width: "100%",
      }}
      slideSize="50%"
      breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: 6 }]}
      slideGap="md"
      align="start"
      slidesToScroll={mobile ? 1 : 2}
      classNames={classes}
    >
      {slides}
    </Carousel>
  );
}
