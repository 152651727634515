import { useStore } from "../../store/store";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { PencilFill } from "react-bootstrap-icons";
import _ from "underscore";
import Config from "../../config/config";
import moment from "moment";
import { Form } from "react-bootstrap";
import { SimpleGrid, TextInput } from "@mantine/core";
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  maxZoom: 20,
  minZoom: 6,
};
type Map = google.maps.Map & { zoom: number };
function TrackTeamMember() {
  const GOOGLE_API_KEY: any = process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : ''
  const { isLoaded: isMapLoaded } = useJsApiLoader({ googleMapsApiKey: GOOGLE_API_KEY, });
  const [center, setCenter] = useState<any>();
  const [zoom, setZoom] = useState<number>(options.minZoom);
  const mapRef = useRef<Map>();

  useEffect(() => {
    setMapCenter()
  }, [])

  const setMapCenter = () => {
    setZoom(7)
    setCenter({ lat: 51.505575158735006, lng: -0.11656980349653838 })
  }

  useEffect(() => {
    console.log('center=>', center);

  }, [center])


  if (!isMapLoaded) return null;

  function handleZoomChanged() {
    if (mapRef.current) {
      setZoom(mapRef.current?.zoom);
    }
  }

  function handleMapLoad(map: google.maps.Map) {
    mapRef.current = map as Map;
  }

  return (
    <div className="m-3 w-100" style={{ height: window.innerHeight }}>
      <div className='map-mobile' style={{ top: 10, transform: "translateY(-16px)", display: 'flex', height: '-webkit-fill-available' }}>
        {center &&
          <GoogleMap
            onLoad={handleMapLoad}
            onZoomChanged={handleZoomChanged}
            mapContainerStyle={{ width: '100%', flexGrow: "1", height: "100%" }}
            options={options}
            center={center}
            zoom={10}
          >
          </GoogleMap>
        }
      </div>
    </div>
  );
}

export default TrackTeamMember;
