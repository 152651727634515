import { useStore } from "../store/store";
import {
  Group,
  Card,
  Badge,
  Button,
  Text,
  useMantineTheme,
  SimpleGrid,
  Image,
  LoadingOverlay,
} from "@mantine/core";
import useErrandListStore from "../store/errand-list.store";
import moment from "moment";
import { useEffect, useState } from "react";
import Avatar from "../assets/images/avatar.png";
import Config from "../config/config";
import axios from "axios";
import { ViewDetails } from "./view-details";
import { useModals } from "@mantine/modals";
function UnAssignedErrandList(props: any) {
  const { enums, setEnums } = useStore();
  const theme = useMantineTheme();
  const { errandList, fetchErrandList } = useErrandListStore();
  const [loading, setLoading] = useState(true);
  const modals = useModals();
  const openViewDetails = async (errandUniqueId) => {
    const id = modals.openModal({
      title: "Errand Details",
      classNames: { modal: 'info-modal-width' },
      children: (
        <>
          <ViewDetails errandUniqueId={errandUniqueId} />
        </>
      ),
    });
  };
  useEffect(() => {
    const init = async () => {
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }

      await fetchErrandList([2, 3]);
      setLoading(false);
    };
    init();
  }, []);
  return (
    <>
      <LoadingOverlay visible={loading} />
      <SimpleGrid spacing="xs" className="w-100 px-3">
        {errandList && enums && errandList.length ? (
          errandList.map((errand) => {
            if (
              errand.status === enums.ERRAND_STATUS.UNASSIGNED.value ||
              errand.status === enums.ERRAND_STATUS.ASSIGNED.value
            ) {
              return (
                <Card shadow="sm" p="lg">
                  <div className="d-flex">
                    <div style={{ width: "15%", marginRight: "5px" }}>
                      <Image
                        radius={100}
                        src={
                          errand.profilePic
                            ? `data:image/png;base64,${errand.profilePic}`
                            : Avatar
                        }
                        alt="Profile Picture"
                        classNames={{
                          root: "errand-list-image",
                          image: "errand-list-img",
                        }}
                        styles={{
                          caption: { fontWeight: 500, fontSize: "1rem" },
                        }}
                        caption={
                          errand.partnerDetail
                            ? errand.partnerDetail.user.firstName
                            : ""
                        }
                      />
                    </div>
                    <div style={{ width: "80%", marginLeft: '5px' }}>
                      <Group
                        position="apart"
                        className="m-0"
                        style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
                      >
                        <Text weight={600}>{errand.deliveryType.name}</Text>
                        <Group>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "#170052", to: "#4E3882" }}
                          >
                            {errand.errandUniqueId}
                          </Badge>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "#e33960", to: "#e33980" }}
                          >
                            {errand.status == 2 ? "ACCEPTED" : "ASSIGNED"}
                          </Badge>
                        </Group>
                      </Group>

                      <Text size="sm" style={{ lineHeight: 1.5 }}>
                        {errand.additionalDetails}
                      </Text>
                      <hr></hr>
                      <table className="display-sm-none">
                        <tr className="px-5">
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Pickup
                            </Text>
                          </td>
                          <td className="px-5">
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {errand.pickUpLocation.street +
                                "," +
                                errand.pickUpLocation.postcode}
                            </Text>
                          </td>
                          {/* </tr>
                    <tr> */}
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Pickup Date
                            </Text>
                          </td>
                          <td>
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {moment(errand.pickUpTime).format(
                                "ddd, MM D YYYY, h:mm a"
                              )}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Deliver To
                            </Text>
                          </td>
                          <td className="px-5">
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {errand.deliveryLocation.street +
                                "," +
                                errand.deliveryLocation.postcode}
                            </Text>
                          </td>
                          {/* </tr>
                    <tr> */}
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Delivery Date
                            </Text>
                          </td>
                          <td>
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {moment(errand.deliveryTime).format(
                                "ddd, MM D YYYY, h:mm a"
                              )}
                            </Text>
                          </td>
                        </tr>
                      </table>
                      <Group position="left" className="my-3">
                        <Button
                          variant="light"
                          color="red"
                          onClick={() => {
                            window.open(
                              'http://myerrand.co.uk/',
                              '_blank'
                            );
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="light"
                          color="blue"
                          onClick={() => {
                            openViewDetails(errand.errandUniqueId)
                          }}
                        >
                          View Details
                        </Button>
                      </Group>
                    </div>
                  </div>
                </Card>
              );
            }
          })
        ) : (
          <></>
        )}
        {!loading && !errandList.length ? (
          <Text
            size="xl"
            weight={700}
            style={{ textAlign: "center" }}
            className={"text-primary"}
          >
            You Haven't Posted Any Errands Yet.
          </Text>
        ) : (
          <></>
        )}
      </SimpleGrid>
    </>
  );
}

export default UnAssignedErrandList;
