import Moment from 'moment';

export const formateDateDDMMYY = (date:any) =>{
    return (Moment(date).utc().format('DD-MM-YYYY'));
}

export const formateOrderDateTime = (date:any) =>{
    return (Moment(date).utc().format('DD-MM-YYYY HH:mm'));
}

export const formateOrderDateTimeDOB = (date:any) =>{
    return (Moment(date).utc().format('YYYY-MM-DDTHH:mm:ss'));
}