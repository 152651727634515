import hold from '../assets/images/hold.png'
function HoldingPage() {
  return (
    <div className="d-flex flex-column w-75 mx-auto mt-4">
      <img className='mx-auto' src={hold} width='400px' height='400px'></img>

      <h3 className="text-primary text-center">Sit tight. We're just verifying your business details.</h3>
      <p className="h5  text-center">
        This can take upto 24 hours.Please try Logging in again if you still face any issues.
      </p>
      {/* <p className="h5 text-primary text-center">
      From June 15, 2022, you will be able to create Errands for our verified Partners to fulfill, so please come back then to post and have all of your Errands completed.
      </p> */}
    </div>
  );
}

export default HoldingPage;
