import create from "zustand"
import axios from 'axios';
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import Config from "../config/config";


const initialState = {
  paymentList: [],
  loading: false,
  error: "",
}

const usePaymentStore = create<any>((set: any) => ({
  paymentList: initialState.paymentList,
  loading: initialState.loading,
  error: initialState.error,

  fetchPaymentList: async () => {
    set((state: any) => ({ ...state, loading: true }))
    try {
      const { data } = await axios.get(`${Config.baseUrl}/payments/paymentDetails`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({
        ...state, error: "", paymentList: [...data]
      }))
    } catch (error) {
      set((state: any) => ({
        ...state, error: "", paymentList: []
      }))
      // showNotification({
      //   id: "contact",
      //   disallowClose: true,
      //   autoClose: 5000,
      //   title: "Cannot fetch Contact details",
      //   message: "",
      //   color: "red",
      //   loading: false,
      // });
    }
  },

  addPayment: async (cardDetails: any) => {
    // update shift
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/payments/addCard/`,
        {
          number: cardDetails.number,
          expMonth: parseInt(moment(cardDetails.date).format("M")),
          expYear: moment(cardDetails.date).get("y"),
          holderName: cardDetails.holderName,
          cvc: cardDetails.cvc
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data) {
        showNotification({
          id: "payment-not-success",
          disallowClose: true,
          autoClose: 2000,
          title: "Payment",
          message: "Payment Method Successfully Added",
          color: "green",
          loading: false,
        });
      }
    } catch (err: any) {
      let message = 'Cannot add the shift';
      if (!err.response.data.error) {
        if (Array.isArray(err.response.data.message)) {
          message = err.response.data.message[0]
        } else {
          message = err.response.data.message
        }
      } else {
        message = err.response.data.error
      }
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 5000,
        title: "Customer",
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  updateCustomerList: async (customer: any) => {
    try {
      const response = await axios.patch(`${Config.baseUrl}/contactUser`, customer, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      showNotification({
        id: "shift",
        disallowClose: true,
        autoClose: 1000,
        title: "Shift",
        message: 'Shift Updated successfully',
        color: "green",
        loading: false,
      })
      const { data } = await axios.get(`${Config.baseUrl}/contacts`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, error: "", customerList: data[0].contactUsers }))
    } catch (err: any) {
      console.log(err.response);
      let message = 'Cannot update shift';
      if (Array.isArray(err.response.data.message)) {
        message = err.response.data.message[0]
      } else {
        message = err.response.data.message
      }
      showNotification({
        id: "login",
        disallowClose: true,
        autoClose: 5000,
        title: 'Update Shift',
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  deletePayment: async (id: number) => {
    try {
      let { data, status } = await axios.delete(
        `${Config.baseUrl}/payments/deletePayment/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      set((state: any) => ({ ...state, paymentList: state.paymentList.filter((e: any) => e.id !== id) }))
      showNotification({
        id: "contact",
        disallowClose: true,
        autoClose: 1000,
        title: "Card",
        message: 'Card was removed',
        color: "green",
        loading: false,
      })
    } catch (err: any) {
      let message = 'Card was not removed';
      if (err.response && err.response.data) {
        message = err.response.data.message
      }
      showNotification({
        id: "login",
        disallowClose: true,
        autoClose: 5000,
        title: "Card",
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  defaultPayment: async (id: string) => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/payments/default/${id}`,{},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        }
      );
      if (data) {
        showNotification({
          id: "payment-not-success",
          disallowClose: true,
          autoClose: 2000,
          title: "Payment",
          message: "Default Card changed successfully",
          color: "green",
          loading: false,
        });
      }
    } catch (err: any) {
      let message = "Cannot add the shift";
      if (!err.response.data.error) {
        if (Array.isArray(err.response.data.message)) {
          message = err.response.data.message[0];
        } else {
          message = err.response.data.message;
        }
      } else {
        message = err.response.data.error;
      }
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 5000,
        title: "Payment",
        message: message,
        color: "red",
        loading: false,
      });
    }
  }
}))

export default usePaymentStore
