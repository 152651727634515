import useErrandListStore from "../store/errand-list.store";
import { useEffect, useState } from "react";
import {
  Badge,
  Divider,
  Group,
  Paper,
  SimpleGrid,
  Spoiler,
  Text,
  Image,
  useMantineTheme,
} from "@mantine/core";
import moment from "moment";
import axios from "axios";
import Config from "../config/config";
import { useStore } from "../store/store";
import { CardsCarousel } from "./card-carousel.component";
import { useModals } from "@mantine/modals";
import { useMediaQuery } from "@mantine/hooks";

export function ViewDetails({ errandUniqueId }) {
  const modals = useModals();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const openImageModal = (url) => {
    const id = modals.openModal({
      title: "Image",
      classNames: {
        title: "f3 b",
      },
      size: `${mobile ? "100%" : "40%"}`,
      children: (
        <Image
          height={mobile ? "100%" : 400}
          width={mobile ? "100%" : 400}
          src={url}
        />
      ),
    });
  };

  let { errandList } = useErrandListStore();
  const [errand, setErrand] = useState<any>();
  let { enums, setEnums } = useStore();
  useEffect(() => {
    let foundErrand = errandList.find(
      (e) => e.errandUniqueId === errandUniqueId
    );
    setErrand(foundErrand);
    let getEnums = async () => {
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }
    };
    getEnums();
  }, []);
  return errand ? (
    <div>
      <Group grow>
        <Text size="lg">{errand.deliveryType.name}</Text>
        <Group position="right">
          <Badge
            variant="gradient"
            gradient={{ from: "#170052", to: "#4E3882" }}
          >
            {errand.errandUniqueId}
          </Badge>
          <Badge
            variant="gradient"
            gradient={{ from: "#ebad1f", to: "orange" }}
          >
            {errand.priority === 1 ? "Standard" : "Priority"}
          </Badge>
        </Group>
      </Group>
      <Divider className="my-3" size="xs" />
      <Group grow>
        <SimpleGrid cols={2}>
          {errand.senderContactNumber ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Sender Number"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                +44 {errand.senderContactNumber}
              </Text>
            </>
          ) : (
            <></>
          )}
          <Text className="text-primary" size="md" weight={600}>
            {"Sender Name"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.senderName}
          </Text>
          {errand.receiverContactNumber ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Recipient Number"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                +44 {errand.receiverContactNumber}
              </Text>
            </>
          ) : (
            <></>
          )}
          <Text className="text-primary" size="md" weight={600}>
            {"Recipient Name"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.receiverName}
          </Text>

          <Text className="text-primary" size="md" weight={600}>
            {"Pickup from"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.pickUpLocation.street +
              "," +
              errand.pickUpLocation.postcode}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Deliver to"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.deliveryLocation.street +
              "," +
              errand.deliveryLocation.postcode}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Pick up time"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {moment(errand.pickUpTime).format("ddd, MM D YYYY, h:mm a")}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Deliver time"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {moment(errand.deliveryTime).format("ddd, MM D YYYY, h:mm a")}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Delivery Weight"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.deliveryWeight.name}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Delivery Size"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            {errand.deliverySize.name}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Errand Fee"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            £ {parseFloat(errand.price).toFixed(2)}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Priority Price"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            £{" "}
            {errand.priority === 1
              ? enums.ERRAND_PRIORITY.STANDARD.price
              : enums.ERRAND_PRIORITY.PRIORITY.price}
          </Text>
          <Text className="text-primary" size="md" weight={600}>
            {"Final Price"}
          </Text>
          <Text className="text-primary" size="sm" weight={400}>
            £ {parseFloat(errand.discountedPrice).toFixed(2)}
          </Text>
          {errand.collectionErrandName ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Collection Name"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                {errand.collectionErrandName}
              </Text>
            </>
          ) : (
            <></>
          )}
          {errand.collectionErrandNumber ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Collection Number"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                {errand.collectionErrandNumber}
              </Text>
            </>
          ) : (
            <></>
          )}
          {errand.expenditure ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Expenditure"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                £ {errand.expenditure}
              </Text>
            </>
          ) : (
            <></>
          )}
          {errand.expenditureDescription ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Expenditure Description"}
              </Text>
              <Spoiler
                className="text-primary"
                maxHeight={90}
                showLabel="Show more"
                hideLabel="Hide"
              >
                {" "}
                {errand.expenditureDescription}
              </Spoiler>
            </>
          ) : (
            <></>
          )}
          {errand.partnerDetail ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Assigned to"}
              </Text>
              <Text className="text-primary" size="sm" weight={400}>
                {errand.partnerDetail.user.firstName}
              </Text>
            </>
          ) : (
            <></>
          )}
          <Text className="text-primary" size="md" weight={600}>
            {"Additional Details"}
          </Text>
          <Spoiler
            className="text-primary"
            maxHeight={90}
            showLabel="Show more"
            hideLabel="Hide"
          >
            {errand.additionalDetails}
          </Spoiler>
          {errand.shoppingList ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Shopping List"}
              </Text>
              <Spoiler
                className="text-primary"
                maxHeight={90}
                showLabel="Show more"
                hideLabel="Hide"
              >
                {errand.shoppingList}
              </Spoiler>{" "}
            </>
          ) : (
            <></>
          )}
          {errand.postageDetails ? (
            <>
              <Text className="text-primary" size="md" weight={600}>
                {"Shopping List"}
              </Text>
              <Spoiler
                className="text-primary"
                maxHeight={90}
                showLabel="Show more"
                hideLabel="Hide"
              >
                {errand.postageDetails}
              </Spoiler>{" "}
            </>
          ) : (
            <></>
          )}
        </SimpleGrid>
      </Group>
      <Group className="mt-4">
        <Text className="text-primary" size="md" weight={600}>
          {"Files"}
        </Text>
        <CardsCarousel
          data={errand.files.map((file) => {
            return { image: `${Config.baseUrl}/file/${file.id}` };
          })}
        ></CardsCarousel>
      </Group>

      {errand && errand.proofOfDelivery ? (
        <div className="my-4">
          <Text className="text-primary mb-4" size="md" weight={600}>
            {"Proof of Delivery"}
          </Text>
          <Paper
            shadow="md"
            p="xl"
            radius="md"
            styles={{
              root: {
                width: "100vh",
              },
            }}
            onClick={() => {
              openImageModal(
                `${Config.baseUrl}/file/${errand.proofOfDelivery.id}`
              );
            }}
            sx={{
              backgroundImage: `url(${Config.baseUrl}/file/${errand.proofOfDelivery.id})`,
            }}
            style={{
              height: 250,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          ></Paper>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
}
