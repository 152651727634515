import { useStore } from "../../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { PencilFill } from "react-bootstrap-icons";
import _ from "underscore";
import Config from "../../config/config";
import moment from "moment";
import { Button, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Card, SimpleGrid, TextInput } from "@mantine/core";
import LoadingSpinner from "../../components/loader";
import DataTable from "react-data-table-component";
import { formateDateDDMMYY } from "../../config/dateTime-helper";

function TeamMemberList() {
  const [isLoading, setIsLoading] = useState(false);

  const [teamMemberList, setTeamMemberList] = useState([])

  const [canPreviousPage, setCanPreviousPage] = useState<any>(false)
  const [canNextPage, setCanNextPage] = useState<any>(false)
  const [totalPages, setTotalPages] = useState<any>(1)
  const [totalCount, setTotalCount] = useState<any>(1)
  const [fromData, setFromData] = useState<any>(1);
  const [toData, setToData] = useState<any>(10);

  const limit = 10;
  const [offset, setOffset] = useState<any>(0)


  const columns: any = [
    {
      name: "ID",
      selector: row => [row.id],
      sortable: false,
      width: "5rem",
      cell: row => <span className="text-wrap align-middle fs-11"> {row?.id} </span>
    },
    {
      name: "First Name",
      sortable: false,
      width: "11rem",
      cell: row => <span className="text-wrap align-middle fs-11">{`${`${row?.user?.firstName || 'N/A'}`}`}</span>

    },
    {
      name: "Last Name",
      sortable: false,
      width: "11rem",
      cell: row => <span className="text-wrap align-middle fs-11">{`${`${row?.user?.lastName || 'N/A'}`}`}</span>
    },
    {
      name: "Email",
      sortable: false,
      width: "12rem",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.user?.email ? row?.user?.email : "N/A"}`}</span>
    },
    {
      name: "Mobile Number",
      sortable: false,
      width: "11rem",
      cell: row =>
        <span className="text-wrap align-middle fs-11">{`+${row?.user?.countryCode?.dial} ${row?.user?.contactNumber}`}</span>
    },
    {
      name: "City",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.city ? row?.city : "N/A"}`}</span>
    },
    {
      name: "Gender",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.gender ? row?.gender : 'N/A'}`}</span>
    },
    {
      name: "Vehicle",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.vehicle ? row?.vehicle : 'N/A'}`}</span>
    },
    {
      name: "Vehicle Reg Number",
      sortable: false,
      width: "7rem",
      cell: row => <span className="text-wrap align-middle fs-11">{row?.vehicleRegNumber || 'N/A'}</span>
    },
    {
      name: "DOB",
      sortable: true,
      width: "12rem",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.user?.dateOfBirth ? formateDateDDMMYY(row?.user?.dateOfBirth) : ''}`}</span>
    },
    {
      name: "Stores",
      sortable: true,
      width: "12rem",
      cell: row => <span className="text-wrap align-middle fs-11">{`${row?.stores ? row?.stores : ''}`}</span>
    },
    {
      name: "Action",
      sortable: false,
      width: "15rem",
      cell: row => <div>
        <OverlayTrigger placement="top" overlay={<Tooltip >To update team member details</Tooltip>}>
          <Button variant="primary" className="text-center align-middle fs-11 mb-1 me-1 fw-bold" onClick={() => {
          }}>{'Edit'}</Button></OverlayTrigger>
        <OverlayTrigger placement="top" overlay={<Tooltip >To delete the team member details</Tooltip>}>
          <span className="btn btn-primary text-wrap align-middle fs-11 mb-1 me-1 fw-bold" onClick={() => {
          }}>Delete</span>
        </OverlayTrigger>
      </div>
    }
  ]

  const previousPage = async (offset) => {
    setOffset(offset);
    // getStoreData(offset);
    if (offset == 0) {
      setCanPreviousPage(false)
    }
  }
  const nextPage = async (offset) => {
    setOffset(offset);
    // getStoreData(offset);
    setCanPreviousPage(true)
  }

  return (
    <div className="m-3 w-100">
      {isLoading ?
        <>
          <div style={{ width: '100%', height: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingSpinner />
          </div>

        </> : <>
          <Card shadow="sm" p="lg">
            <div className="grid-margin">
              <div className="">
                <div className='table-responsive'>
                  <DataTable className="border-bottom"
                    columns={columns}
                    data={teamMemberList}
                    noHeader
                    defaultSortAsc={true}
                    highlightOnHover
                    dense
                  />
                  {totalPages > 0 &&
                    <div className="d-block d-sm-flex mt-4 ">
                      <Col xs={11} lg={6} xl={6} md={6} style={{ display: 'flex', alignItems: 'center' }}>
                        <span className="">
                          Page{" "}
                          <strong>
                            {offset + 1} of {totalPages}
                          </strong>{" "}
                        </span>
                      </Col>
                      <Col xs={11} lg={6} xl={6} md={6}>
                        <span className="ms-sm-auto float-end">
                          <span className="me-2">
                            <strong>
                              {`${fromData}-${toData}`} of {totalCount}
                            </strong>{" "}
                          </span>
                          <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                              previousPage(0);
                            }}
                            disabled={!canPreviousPage}
                          >
                            {" << "}
                          </Button>
                          <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                              const mOffset = offset - 1;
                              previousPage(mOffset);
                            }}
                            disabled={!canPreviousPage}
                          >
                            {" < "}
                          </Button>
                          <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                              const mOffset = offset + 1;
                              nextPage(mOffset);
                            }}
                            disabled={!canNextPage}
                          >
                            {" > "}
                          </Button>
                          <Button
                            variant=""
                            className="btn-default tablebutton me-2 my-2"
                            onClick={() => {
                              const mOffset = totalPages - 1;
                              nextPage(mOffset);
                            }}
                            disabled={!canNextPage}
                          >
                            {" >> "}
                          </Button>
                        </span>
                      </Col>
                    </div>
                  }
                </div>
              </div>
            </div>
          </Card>
        </>}
    </div>
  );
}

export default TeamMemberList;
