
import create from "zustand";

export const useAppStore = create<any>((set: any) => ({
    isLoggedIn: false,
    setLoggedIn: () => {
        set((state: any) => ({ ...state, isLoggedIn: true }))
    },
    logout: () => {
      localStorage.setItem('token', '');
      set((state: any) => ({ ...state, isLoggedIn: false }))
  },
}))
