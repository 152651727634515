import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import useErrandListStore from "../store/errand-list.store";
var stylingObject = {
  mainDiv: {
    width: "15%",
  },
};
function OpenErrandsNav() {
  let navigate = useNavigate();
  let {resetErrandList} = useErrandListStore()
  const [inprogress, setInprogress] = useState(true);
  const [upcoming, setUpcoming] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const location = useLocation();
  useEffect(() => {
    resetErrandList();
    // runs on location, i.e. route, change
    let path = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    if (path == "in-progress") {
      setInprogress(true);
      setUpcoming(false);
      setIncomplete(false);
    } else if (path == "upcoming") {
      setInprogress(false);
      setUpcoming(true);
      setIncomplete(false);
    } else if (path == "incomplete") {
      setInprogress(false);
      setUpcoming(false);
      setIncomplete(true);
    }
  }, [location]);
  async function doNavigate(path: string) {
    switch (path) {
      case "in-progress":
        setInprogress(true);
        setUpcoming(false);
        setIncomplete(false);
        break;
      case "upcoming":
        setInprogress(false);
        setUpcoming(true);
        setIncomplete(false);
        break;
      case "incomplete":
        setInprogress(false);
        setUpcoming(false);
        setIncomplete(true);
        break;
    }
    navigate(path);
  }
  return (
    <>
      <div style={stylingObject.mainDiv} className='step-nav'>
        <ul className="list-unstyled">
          <li
            className={`side-nav-custom ${inprogress ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate("in-progress");
            }}
          >
            In Progress
          </li>
          <li
            className={`side-nav-custom ${upcoming ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate("upcoming");
            }}
          >
            Upcoming
          </li>
          <li
            className={`side-nav-custom ${incomplete ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate("incomplete");
            }}
          >
            Incomplete
          </li>
        </ul>
      </div>
    </>
  );
}

export default OpenErrandsNav;
