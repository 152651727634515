import Config from "../../config/config";
import { useStore } from "../../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
var stylingObject = {
  mainDiv: {
    width: "20%",
  },
};
function TeamNav() {
  let navigate = useNavigate();
  const [teamNav, setTeamNav] = useState(1);
  async function doNavigate(path: number) {
    switch (path) {
      case 1:
        navigate("team-members");
        break;
      case 2:
        navigate("create");
        break;
      case 3:
        navigate("track");
        break;

      default:
        break;
    }
    setTeamNav(path);
  }
  return (
    <>
      <div style={stylingObject.mainDiv} className='step-nav'>
        <ul className="list-unstyled">
          <li
            className={`side-nav-custom ${teamNav === 1 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(1);
            }}
          >
            Team Member List
          </li>
          <li
            className={`side-nav-custom ${teamNav === 2 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(2);
            }}
          >
            Create Member
          </li>
          <li
            className={`side-nav-custom ${teamNav === 3 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(3);
            }}
          >
            Track Members
          </li>
        </ul>
      </div>
    </>
  );
}

export default TeamNav;
