import { Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";
function EmailVerified() {
    let navigate = useNavigate();
    return (
        <>
            <div
                className="container w-75 d-flex flex-column gap-3 email-container"
                style={{ position: "relative", bottom: "100%" }}
            >
                <br></br>
                <div className="text-center">
                    <svg
                        width="150"
                        height="150"
                        viewBox="0 0 275 244"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M224.757 202.869H14.2203C7.47073 202.869 2 197.399 2 190.649V56.9054C2 50.1558 7.47073 44.6851 14.2203 44.6851H224.757C231.507 44.6851 236.977 50.1558 236.977 56.9054V190.649C236.977 197.399 231.507 202.869 224.757 202.869Z"
                            fill="#170052"
                            stroke="white"
                            strokeWidth="4"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M236.977 56.9054V57.1185L126.396 143.25C122.346 146.416 116.652 146.416 112.592 143.25L2 57.1794V56.9054C2 50.1558 7.47073 44.6851 14.2203 44.6851H224.757C231.507 44.6749 236.977 50.1558 236.977 56.9054Z"
                            fill="#170052"
                            stroke="white"
                            strokeWidth="4"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M230.441 103.442C253.979 103.442 273.06 84.3613 273.06 60.8235C273.06 37.2857 253.979 18.2046 230.441 18.2046C206.903 18.2046 187.822 37.2857 187.822 60.8235C187.822 84.3613 206.903 103.442 230.441 103.442Z"
                            fill="#170052"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M252.446 63.2494L215.338 79.0526C212.638 80.1995 209.928 77.4286 211.136 74.7491L212.963 70.7297L231.842 62.5998C233.801 61.7472 233.801 58.9662 231.831 58.1237L213.278 50.1358L211.41 45.7004C210.324 43.1325 212.933 40.5545 215.49 41.6608L252.446 57.6974C254.882 58.7733 254.871 62.2141 252.446 63.2494Z"
                            fill="#EAAC1C"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M231.842 62.6099L212.963 70.7399L216.363 63.2697C217.165 61.4934 217.196 59.4432 216.444 57.6365L213.288 50.146L231.842 58.1339C233.801 58.9763 233.801 61.7675 231.842 62.6099Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="2"
                            strokeMiterlimit="10"
                        />
                        <path
                            d="M150.461 22.3759L158.753 14.0835L167.32 22.6398"
                            stroke="#170052"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M81.1274 233.441L88.009 226.569L95.1037 233.664"
                            stroke="#170052"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M238.784 230.852L245.31 224.336L252.04 231.065"
                            stroke="#170052"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M185.792 14.0024C188.83 14.0024 191.293 11.5394 191.293 8.50118C191.293 5.46296 188.83 3 185.792 3C182.754 3 180.291 5.46296 180.291 8.50118C180.291 11.5394 182.754 14.0024 185.792 14.0024Z"
                            stroke="#170052"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M119.494 241.114C122.532 241.114 124.995 238.651 124.995 235.613C124.995 232.574 122.532 230.111 119.494 230.111C116.456 230.111 113.993 232.574 113.993 235.613C113.993 238.651 116.456 241.114 119.494 241.114Z"
                            stroke="#170052"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                </div>

                <div className="h3 text-primary text-center">Email Verification</div>
                <div className="h5 text-primary text-center">
                    Congratulations! Your email has been successfully verified.
                </div>
                {/* <Group position="center" mt="lg">
                    <Button className="bg-danger" type="submit" onClick={() => { navigate('/login') }}>
                        Go Back
                    </Button>
                </Group> */}
            </div>
        </>
    );
}

export default EmailVerified;
