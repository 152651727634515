import useErrandListStore from "../store/errand-list.store";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import ErrandList from "./errand-list";
import OpenErrandsNav from "./open-errands-nav";
import { useStore } from "../store/store";
import Config from "../config/config";
import axios from "axios";
import UnAssignedErrandList from "./unassigned-errand-list";
import IncompleteErrandList from "./incomplete-errand-list";

function OpenErrand() {
  const { fetchErrandList } = useErrandListStore();
  const { enums, setEnums } = useStore();
  let navigate = useNavigate();

  useEffect(() => {
    let getEnums = async () => {
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }
    };
    getEnums();
  }, [navigate]);
  return (
    <div style={{ paddingLeft: "16px" }}>
      <div>
        <h3 className="text-danger">Errands</h3>
        <hr></hr>
      </div>
      <div className="d-flex" style={{ marginTop: "35px" }}>
        <OpenErrandsNav />
        <Routes>
          <Route
            path="in-progress"
            element={
              <ErrandList
                type="inProgress"
                status={
                  enums && enums.ERRAND_STATUS
                    ? enums.ERRAND_STATUS.INPROGRESS.value
                    : ""
                }
                statusLabel={
                  enums && enums.ERRAND_STATUS
                    ? enums.ERRAND_STATUS.INPROGRESS.label
                    : ""
                }
              />
            }
          />
          <Route
            path="upcoming"
            element={<UnAssignedErrandList />}
          />
          <Route path="incomplete" element={<IncompleteErrandList  />} />
        </Routes>
      </div>
    </div>
  );
}

export default OpenErrand;
