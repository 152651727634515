import axios from "axios";
import type { AxiosRequestConfig } from "axios";
import Config from "../config/config";

/**
 * Creates an initial 'axios' instance with custom settings.
 */
const AxiosInstance = axios.create({
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
  },
});

// Request interceptor for API calls
AxiosInstance.interceptors.request.use(
  async config => {
    config.headers = {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
      'Accept': 'application/json'
    }
    return config;
  },
  error => {
    Promise.reject(error)
  });

/**
 * Handle all responses. It is possible to add handlers
 * for requests, but it is omitted here for brevity.
 */
// Response interceptor for API calls
AxiosInstance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  let originalRequest = error.config;
  let refreshToken = localStorage.getItem('refreshToken');
  if (error.response.data.statusCode === 401 && refreshToken) {
    const accessToken = await refreshAccessToken(refreshToken);
    AxiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
    localStorage.setItem('token', accessToken)
    return AxiosInstance(originalRequest);
  }
  return Promise.reject(error);
});

/**
 * Replaces main `axios` instance with the custom-one.
 *
 * @param cfg - Axios configuration object.
 * @returns A promise object of a response of the HTTP request with the 'data' object already
 * destructured.
 */


async function refreshAccessToken(refreshToken: string) {
  try {
    let { data, status }: any = await AxiosInstance.post(`${Config.baseUrl}/refreshToken`, {
      refreshToken: refreshToken
    });
    return data.token;
  } catch (err) {
    throw err
  }
}

export default AxiosInstance;


