import Config from "../config/config";
import { GlobalEnums } from "@/store/global-enums";
import { ArrowLeft } from "react-bootstrap-icons";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import _ from "underscore";
function RegisterBusiness() {
  let {
    setErrorMessage,
    enums,
    registerUser,
    deleteRegisterUser,
    setRegisterUser,
    registerBusiness,
    setRegisterBusiness,
    resetRegisterBusiness,
    resetRegisterUser
  } = useStore();
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    if (!registerUser.email) {
      navigate("/register");
    }
    validateInput()
  }, []);

  let register = async () => {
    try {
      let { data, status } = await axios.post(`${Config.baseUrl}/business`, {
        dateOfBirth: registerUser.dob,
        contactNumber: registerUser.phoneNumber,
        email: registerUser.email,
        password: registerUser.password,
        firstName: registerUser.firstName,
        lastName: registerUser.lastName,
        gender: registerUser.gender,
        isPrivacyPolicyChecked: registerUser.isPrivacyPolicyChecked,
        isTocChecked: registerUser.isTocChecked,
        name: registerBusiness.businessName,
        registrationNumber: registerBusiness.regNumber,
        dailyExpectedErrands: registerBusiness.expectedErrands,
        companyDescription: registerBusiness.description,
        businessType: registerBusiness.businessType,
        street: registerBusiness.street,
        postcode: registerBusiness.postcode,
        flatNumber: registerBusiness.buildingName,
        city: registerBusiness.city,
        otherBusinessDescription: registerBusiness.otherDescription,
      });
      if (status === 201) {
        //if(registerUser.firstName == 'Test'){
          
          localStorage.setItem("contactNumber", registerUser.phoneNumber);
          localStorage.setItem("userTempToken", data.userTempToken);
          localStorage.setItem("email", data.email);
          localStorage.setItem("userTempToken", data.userTempToken);
          deleteRegisterUser();
          setErrorMessage({error: "User successfully registered --", isError: false, show: true });
          resetRegisterBusiness();
          resetRegisterUser();
          navigate("/phoneotp-sent");

        // }else{
        //   deleteRegisterUser();
        //   localStorage.setItem("email", data.email);
        //   localStorage.setItem("userTempToken", data.userTempToken);
        //   setErrorMessage({error: "User successfully registered", isError: false, show: true });
        //   resetRegisterBusiness();
        //   resetRegisterUser();
        //   navigate("/email-sent");
        // }
        
      } else {
        setErrorMessage({error: "Something went wrong", isError: true, show: true });
      }
    } catch (error: any) {
      console.log(error);
      setErrorMessage({ error: error.response.data, isError: true, show: true });
    }
  };

  const validateInput = () => {
    if (
      registerBusiness.businessType &&
      registerBusiness.description &&
      registerBusiness.expectedErrands &&
      registerBusiness.businessName &&
      registerBusiness.street &&
      registerBusiness.postcode &&
      registerBusiness.city
    ) {
      if (registerBusiness.businessType === enums.BUSINESS_TYPE.SOLE.value) {
        if (!registerBusiness.regNumber) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        if (!registerBusiness.regNumber) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      }
      if (
        registerBusiness.description === enums.COMPANY_DESCRIPTION.OTHER.value
      ) {
        if (registerBusiness.otherDescription) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        setDisabled(false);
      }
    } else {
      setDisabled(true);
    }
  };

  return (
    <>
      <div
        className="col-6 offset-6"
        style={{ position: "relative", top: "25%" }}
      >
        <h3 className="text-primary text-center">
          <ArrowLeft
            className="mx-3 mb-1"
            size={"25"}
            onClick={() => {
              navigate("/register");
            }}
          ></ArrowLeft>
          Business Details
        </h3>
        <div className="row mt-3">
          <div className="col-6 d-flex justify-content-even">
            <div className="w-50">
              <label className="primary" style={{ fontSize: "16px" }}>
                Are you a ?
              </label>
              <input
                name="business-type"
                id="soleTrader"
                value={
                  enums && enums.BUSINESS_TYPE && enums.BUSINESS_TYPE.SOLE.value
                    ? enums.BUSINESS_TYPE.SOLE.value
                    : ""
                }
                checked={
                  enums &&
                  enums.BUSINESS_TYPE &&
                  registerBusiness &&
                  enums.BUSINESS_TYPE.SOLE.value &&
                  registerBusiness.businessType ===
                    enums.BUSINESS_TYPE.SOLE.value
                    ? true
                    : false
                }
                className="opacity"
                type="radio"
                onChange={(event) => {
                  setRegisterBusiness({
                    businessType: parseInt(event.target.value),
                  });
                  validateInput();
                }}
              ></input>
              <label
                htmlFor="soleTrader"
                className="radio-label w-75"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                Sole Trader
              </label>
            </div>
            <div className="w-50 align-self-end">
              <input
                id="limitedCompany"
                name="business-type"
                value={
                  enums &&
                  enums.BUSINESS_TYPE &&
                  enums.BUSINESS_TYPE.LIMITED_COMPANY.value
                    ? enums.BUSINESS_TYPE.LIMITED_COMPANY.value
                    : ""
                }
                checked={
                  enums &&
                  enums.BUSINESS_TYPE &&
                  registerBusiness &&
                  enums.BUSINESS_TYPE.LIMITED_COMPANY.value &&
                  registerBusiness.businessType ===
                    enums.BUSINESS_TYPE.LIMITED_COMPANY.value
                    ? true
                    : false
                }
                className="opacity"
                type="radio"
                onChange={(event) => {
                  setRegisterBusiness({
                    businessType: parseInt(event.target.value),
                  });
                  validateInput();
                }}
              ></input>
              <label
                htmlFor="limitedCompany"
                className="radio-label w-100"
                style={{ fontSize: "16px", textAlign: "center" }}
              >
                Ltd Company
              </label>
            </div>
          </div>
          <div className="form-group col-6">
            <label
              id="businessName"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Business Name
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter Business Name"
              name="businessName"
              value={registerBusiness.businessName}
              onChange={(event) => {
                setRegisterBusiness({ businessName: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className={`form-group col-6 ${
              enums &&
              enums.BUSINESS_TYPE &&
              registerBusiness.businessType === enums.BUSINESS_TYPE.SOLE.value
                ? "disable"
                : ""
            }`}
          >
            <label
              id="companyNumber"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Company Registration Number
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="REG124COMP"
              name="companyNumber"
              value={registerBusiness.regNumber}
              onChange={(event) => {
                setRegisterBusiness({ regNumber: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
          <div className="form-group col-6">
            <label
              id="streetNumber"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Street And Number
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter Street Number"
              name="streetNumber"
              value={registerBusiness.street}
              onChange={(event) => {
                setRegisterBusiness({ street: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col-6">
            <label
              id="buildingName"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Building Name
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter Building Name"
              name="buildingName"
              value={registerBusiness.buildingName}
              onChange={(event) => {
                setRegisterBusiness({ buildingName: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
          <div className="form-group col-6">
            <label
              id="postcode"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Postcode
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter Postcode"
              name="postcode"
              value={registerBusiness.postcode}
              onChange={(event) => {
                setRegisterBusiness({ postcode: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col-6">
            <label id="city" className="primary" style={{ fontSize: "16px" }}>
              City
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter City"
              name="city"
              value={registerBusiness.city}
              onChange={(event) => {
                setRegisterBusiness({ city: event.target.value });
                validateInput();
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <label id="city" className="primary" style={{ fontSize: "16px" }}>
              Which industry best describes your company?
            </label>
            <select
              className="form-control primary"
              value={registerBusiness.description}
              onChange={(event) => {
                setRegisterBusiness({
                  description: parseInt(event.target.value),
                });
                validateInput();
              }}
            >
              <option value="">Select Description</option>
              {
              enums && enums.COMPANY_DESCRIPTION?
              Object.keys(enums.COMPANY_DESCRIPTION).map(key=>{
                return (
                  <option value={enums.COMPANY_DESCRIPTION[key].value}>
                    {enums.COMPANY_DESCRIPTION[key].label}
                  </option>
                )
              })
              :<></>}
            </select>
          </div>
          {enums &&
          enums.COMPANY_DESCRIPTION &&
          enums.COMPANY_DESCRIPTION["OTHER"] &&
          registerBusiness.description ===
            enums.COMPANY_DESCRIPTION["OTHER"].value ? (
            <div className="col-12 mt-3">
              <label
                id="other"
                className="primary"
                style={{ fontSize: "16px" }}
              >
                Other
              </label>
              <input
                value={registerBusiness.otherDescription}
                onChange={(event: any) => {
                  setRegisterBusiness({ otherDescription: event.target.value });
                  validateInput();
                }}
                className="w-100 primary"
                type="text"
                placeholder="Description"
                name="other"
              ></input>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <label className="primary" style={{ fontSize: "16px" }}>
              Approximate errands per day?
            </label>
            <div className="row d-flex justify-content-even">
              <div className="col-3">
                <input
                  id="lessThen10"
                  value={
                    enums &&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.LESS_THEN_10.value
                      ? enums.EXPECTED_ERRANDS.LESS_THEN_10.value
                      : ""
                  }
                  className="opacity"
                  type="radio"
                  name="expectedErrands"
                  checked={
                    enums &&
                    registerBusiness &&
                    registerBusiness.expectedErrands&&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.LESS_THEN_10 &&
                    enums.EXPECTED_ERRANDS.LESS_THEN_10.value === registerBusiness.expectedErrands?true:false
                  }
                  onChange={(event) => {
                    setRegisterBusiness({
                      expectedErrands: parseInt(event.target.value),
                    });
                    validateInput();
                  }}
                ></input>
                <label
                  htmlFor="lessThen10"
                  className="radio-label w-100"
                  style={{ fontSize: "16px", textAlign: "center" }}
                >
                  less then 10
                </label>
              </div>
              <div className="col-3">
                <input
                  id="10to30"
                  value={
                    enums &&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.BETWEEN_10_30.value
                      ? enums.EXPECTED_ERRANDS.BETWEEN_10_30.value
                      : ""
                  }
                  checked={
                    enums &&
                    registerBusiness &&
                    registerBusiness.expectedErrands&&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.BETWEEN_10_30 &&
                    enums.EXPECTED_ERRANDS.BETWEEN_10_30.value === registerBusiness.expectedErrands?true:false
                  }
                  className="opacity"
                  type="radio"
                  name="expectedErrands"
                  onChange={(event) => {
                    setRegisterBusiness({
                      expectedErrands: parseInt(event.target.value),
                    });
                    validateInput();
                  }}
                ></input>
                <label
                  htmlFor="10to30"
                  className="radio-label w-100"
                  style={{ fontSize: "16px", textAlign: "center" }}
                >
                  10 - 30
                </label>
              </div>
              <div className="col-3">
                <input
                  id="30to50"
                  value={
                    enums &&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.BETWEEN_30_50.value
                      ? enums.EXPECTED_ERRANDS.BETWEEN_30_50.value
                      : ""
                  }
                  checked={
                    enums &&
                    registerBusiness &&
                    registerBusiness.expectedErrands&&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.BETWEEN_30_50 &&
                    enums.EXPECTED_ERRANDS.BETWEEN_30_50.value === registerBusiness.expectedErrands?true:false
                  }
                  className="opacity"
                  type="radio"
                  name="expectedErrands"
                  onChange={(event) => {
                    setRegisterBusiness({
                      expectedErrands: parseInt(event.target.value),
                    });
                    validateInput();
                  }}
                ></input>
                <label
                  htmlFor="30to50"
                  className="radio-label w-100"
                  style={{ fontSize: "16px", textAlign: "center" }}
                >
                  30 - 50
                </label>
              </div>
              <div className="col-3">
                <input
                  id="more than 50"
                  value={
                    enums &&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.MORE_THEN_50.value
                      ? enums.EXPECTED_ERRANDS.MORE_THEN_50.value
                      : ""
                  }
                  checked={
                    enums &&
                    registerBusiness &&
                    registerBusiness.expectedErrands&&
                    enums.EXPECTED_ERRANDS &&
                    enums.EXPECTED_ERRANDS.MORE_THEN_50 &&
                    enums.EXPECTED_ERRANDS.MORE_THEN_50.value === registerBusiness.expectedErrands?true:false
                  }
                  className="opacity"
                  type="radio"
                  name="expectedErrands"
                  onChange={(event) => {
                    setRegisterBusiness({
                      expectedErrands: parseInt(event.target.value),
                    });
                    validateInput();
                  }}
                ></input>
                <label
                  htmlFor="more than 50"
                  className="radio-label w-100"
                  style={{ fontSize: "16px", textAlign: "center" }}
                >
                  More than 50
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex mt-3">
          <button
            onClick={() => {
              register();
            }}
            className={`btn-primary text-decoration-none ${
              disabled ? "disabled" : ""
            }`}
            style={{ marginLeft: "auto", width: "25%", textAlign: "center" }}
          >
            Save
          </button>
        </div>
        <span
          className="align-self-end text-primary mb-3"
          style={{ fontSize: "14px" }}
        >
          Already have an account?{" "}
          <span style={{ cursor: "pointer" }}>
            <b>
              <Link className="text-danger text-decoration-none" to="/">
                Login
              </Link>
            </b>
          </span>
        </span>
      </div>
    </>
  );
}

export default RegisterBusiness;
