import { Route, Routes } from "react-router-dom";
import TeamNav from "./team-nav.component";
import TeamMemberList from "../../pages/Team-Members/team-members";
import CreateTeamMember from "../../pages/Team-Members/create-team-members";
import TrackTeamMember from "../../pages/Team-Members/track-team-members";

function TeamMember() {
  return (
    <>
      <div className="px-4">
        <h3 className="text-danger">Team Members</h3>
        <hr></hr>
      </div>
      <div className="d-flex px-4">
        <TeamNav />
        <Routes>
          <Route path="team-members" element={<TeamMemberList />} />
          <Route path="create" element={<CreateTeamMember />} />
          <Route path="track" element={<TrackTeamMember />} />
          <Route path="*" element={<TeamMemberList />} />
        </Routes>
      </div>
    </>
  );
}

export default TeamMember;
