import Profile from "../pages/profile";
import { Route, Routes } from "react-router-dom";
import AccountNav from "./account-nav.component";
import CustomerList from "./customer-list.component";
import ErrandList from "./errand-list";
import OpenErrandsNav from "./open-errands-nav";
import PaymentDetails from "../pages/payment-details";
import BusinessDetails from "./business-details";
import Invoice from "./invoice.component";
import Subscriptions from "../pages/subscriptions";

function Account() {
  return (
    <>
      <div className="px-4">
        <h3 className="text-danger">My Account</h3>
        <hr></hr>
      </div>
      <div className="d-flex px-4">
        <AccountNav />
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route path="business-details" element={<BusinessDetails />} />
          <Route path="customer-list" element={<CustomerList />} />
          <Route path="payment-details" element={<PaymentDetails />} />
          <Route path="invoice" element={<Invoice />} />
          <Route path="subscriptions" element={<Subscriptions />} />
          <Route path="*" element={<Profile />} />
        </Routes>
      </div>
    </>
  );
}

export default Account;
