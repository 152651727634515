import { TrackErrands } from "../pages/track-errands";
import { Routes, Route } from "react-router-dom";
import Account from "./account.component";
import CompletedErrand from "./completed.component";
import CreateErrand from "./create-errand";
import OpenErrand from "./open-errand";
import TeamMember from "./Team-Members/team-member.component";

export function MyerrandNav() {
  return (
    <Routes>
      <Route path="track-errands/*" element={<TrackErrands />} />
      <Route path="create-errand/*" element={<CreateErrand />} />
      <Route path="open-errand/*" element={<OpenErrand />} />
      <Route path="completed-errand/*" element={<CompletedErrand />} />
      <Route path="account/*" element={<Account />} />
      <Route path="internal-team/*" element={<TeamMember />} />
    </Routes>
  );
}
