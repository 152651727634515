import create from "zustand"
import axios from 'axios';
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import Config from "../config/config";


const initialState = {
  customerList: [],
  loading: false,
  error: "",
}

const useCustomerListStore = create<any>((set: any) => ({
  customerList: initialState.customerList,
  loading: initialState.loading,
  error: initialState.error,

  fetchCustomerList: async () => {
    set((state: any) => ({ ...state, loading: true }))
    try {
      const { data } = await axios.get(`${Config.baseUrl}/contacts`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, error: "", customerList: data[0].contactUsers }))
    } catch (error) {
      console.log(error);
      showNotification({
        id: "contact",
        disallowClose: true,
        autoClose: 5000,
        title: "Cannot fetch Contact details",
        message: "",
        color: "red",
        loading: false,
      });
    }
  },

  addToCustomerList: async (customer: any) => {
    // update shift
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/contacts`,
        {
          ...customer,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      console.log(data)
      set((state: any) => ({ ...state, customerList: data.contactUsers }))
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 5000,
        title: "Customer",
        message: "Customer Added successfully",
        color: "red",
        loading: false,
      })
    } catch (err: any) {
      let message = 'Cannot add the shift';
      if (!err.response.data.error) {
        if (Array.isArray(err.response.data.message)) {
          message = err.response.data.message[0]
        } else {
          message = err.response.data.message
        }
      } else {
        message = err.response.data.error
      }
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 5000,
        title: "Customer",
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  updateCustomerList: async (customer: any) => {
    try {
      const response = await axios.patch(`${Config.baseUrl}/contactUser`, customer, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      showNotification({
        id: "shift",
        disallowClose: true,
        autoClose: 1000,
        title: "Shift",
        message: 'Shift Updated successfully',
        color: "green",
        loading: false,
      })
      const { data } = await axios.get(`${Config.baseUrl}/contacts`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, error: "", customerList: data[0].contactUsers }))
    } catch (err: any) {
      console.log(err.response);
      let message = 'Cannot update shift';
      if (Array.isArray(err.response.data.message)) {
        message = err.response.data.message[0]
      } else {
        message = err.response.data.message
      }
      showNotification({
        id: "login",
        disallowClose: true,
        autoClose: 5000,
        title: 'Update Shift',
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  deleteCustomer: async (id: number) => {
    try {
      let { data, status } = await axios.delete(
        `${Config.baseUrl}/contactUser/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      set((state: any) => ({ ...state, customerList: state.customerList.filter((e: any) => e.id !== id) }))
      showNotification({
        id: "contact",
        disallowClose: true,
        autoClose: 1000,
        title: "Customer Details",
        message: 'Customer Details were removed',
        color: "green",
        loading: false,
      })
    } catch (err) {
      showNotification({
        id: "login",
        disallowClose: true,
        autoClose: 5000,
        title: "Customer Details",
        message: "Customer Details were not removed",
        color: "red",
        loading: false,
      })
    }
  },
}))

export default useCustomerListStore
