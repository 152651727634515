/*
 * Copyright 2021 Google LLC. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import * as React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { randomId } from "@mantine/hooks";

const render = (status: Status) => {
  return <h1>{status}</h1>;
};

export const CustomMap: any = ({ pickLatLng, dropLatLng }) => {
  const [clicks, setClicks] = React.useState<google.maps.LatLng[]>([]);
  const [center, setCenter] =
    React.useState<google.maps.LatLngLiteral>(dropLatLng);

  const onIdle = (m: any) => {
    setCenter({ lng: dropLatLng.lng, lat: dropLatLng.lat });
  };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper
        apiKey={"AIzaSyCG8sOyOsgH9B4h4DboUHJprHjNu-hMIuM"}
        render={render}
      >
        <Map
          dropLatLng={dropLatLng}
          pickLatLng={pickLatLng}
          center={center}
          onIdle={onIdle}
          zoom={14}
          mapTypeControl={false}
          mapTypeControlOptions={null}
          style={{ flexGrow: "1", height: "100%" }}
        ></Map>
      </Wrapper>
    </div>
  );
};
interface MapProps extends google.maps.MapOptions {
  style: { [key: string]: string };
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  children?: React.ReactNode;
  pickLatLng: any;
  dropLatLng: any;
}

const Map: React.FC<MapProps> = ({
  onClick,
  onIdle,
  children,
  style,
  ...options
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
    if (map) {
      map.setOptions(options);
      const directionsService = new google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        preserveViewport: true
      });
      directionsRenderer.setMap(map);
      ["click", "idle"].forEach((eventName) =>
        google.maps.event.clearListeners(map, eventName)
      );
      calculateAndDisplayRoute(
        directionsService,
        directionsRenderer,
        options.dropLatLng,
        options.pickLatLng
      );
    }
  }, [ref, map]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946

  React.useEffect(() => {
    if (map) {
      if (onClick) {
        map.addListener("click", onClick);
      }

      if (onIdle) {
        map.addListener("idle", () => onIdle(map));
      }
    }
  }, [map, onClick, onIdle]);

  function calculateAndDisplayRoute(
    directionsService: google.maps.DirectionsService,
    directionsRenderer: google.maps.DirectionsRenderer,
    dropLatLng: any,
    pickLatLng: any
  ) {
    directionsService
      .route({
        origin: {
          query: `${pickLatLng.lat},${pickLatLng.lng}`,
        },
        destination: {
          query: `${dropLatLng.lat},${dropLatLng.lng}`,
        },
        travelMode: google.maps.TravelMode.DRIVING,
      })
      .then((response) => {
        directionsRenderer.setDirections(response);
      })
      .catch((e) => window.alert("Directions request failed due to " + status));
  }
  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
};

const Marker: React.FC<google.maps.MarkerOptions> = (options) => {
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef();
  return ref.current;
}

function useDeepCompareEffectForMaps(
  callback: React.EffectCallback,
  dependencies: any[]
) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
}
