import { useStore } from "../store/store";
import axios from "axios";
import Avatar from "../assets/images/avatar.png";
import { useEffect, useState } from "react";
import {
  Group,
  Card,
  Badge,
  Button,
  Text,
  useMantineTheme,
  SimpleGrid,
  Image,
  LoadingOverlay,
} from "@mantine/core";
import useErrandListStore from "../store/errand-list.store";
import moment from "moment";
import { ViewDetails } from "./view-details";
import { useModals } from "@mantine/modals";

function CompletedErrand(props: any) {
  const theme = useMantineTheme();
  const { enums } = useStore();
  const { errandList, fetchErrandList } = useErrandListStore();
  const [isEmpty, setIsEmpty] = useState(true);
  const [loading, setLoading] = useState(true);
  const modals = useModals();
  const openViewDetails = async (errandUniqueId) => {
    const id = modals.openModal({
      title: "Errand Details",
      classNames: {modal: 'info-modal-width'},
      children: (
        <>
          <ViewDetails errandUniqueId={errandUniqueId}/>
        </>
      ),
    });
  };
  useEffect(() => {
    const init = async () => {
      await fetchErrandList([8]);
      setLoading(false);
    };
    init();
  }, []);
  return (
    <>
    <LoadingOverlay visible={loading} />
      <SimpleGrid spacing="xs" className="w-100 px-5">
        {errandList && errandList.length ? (
          errandList.map((errand) => {
            if (errand.status === enums.ERRAND_STATUS.COMPLETED.value) {
              return (
                <Card shadow="sm" p="lg">
                  <div className="d-flex">
                    <div style={{ width: "100%", marginLeft:'5px' }}>
                      <Group
                        position="apart"
                        style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
                      >
                        <Text weight={600}>{errand.deliveryType.name}</Text>
                        <Group>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "#170052", to: "#4E3882" }}
                          >
                            {errand.errandUniqueId}
                          </Badge>
                          <Badge
                            variant="gradient"
                            gradient={{ from: "#e33960", to: "#e33980" }}
                          >
                            {"COMPLETED"}
                          </Badge>
                        </Group>
                      </Group>
                      <hr></hr>
                      <table className="display-sm-none">
                        <tr className="px-5">
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Pick up from
                            </Text>
                          </td>
                          <td className="px-5">
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {errand.pickUpLocation.street +
                                "," +
                                errand.pickUpLocation.postcode}
                            </Text>
                          </td>
                          {/* </tr>
                    <tr> */}
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Pickup Date
                            </Text>
                          </td>
                          <td className="px-5" style={{ marginLeft: "5px" }}>
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {moment(errand.pickUpTime).format(
                                "ddd, MM D YYYY, h:mm a"
                              )}
                            </Text>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Deliver To
                            </Text>
                          </td>
                          <td className="px-5">
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {errand.deliveryLocation.street +
                                "," +
                                errand.deliveryLocation.postcode}
                            </Text>
                          </td>
                          {/* </tr>
                    <tr> */}
                          <td>
                            <Text
                              weight={500}
                              size="sm"
                              style={{ lineHeight: 1.5 }}
                            >
                              Delivery Date
                            </Text>
                          </td>
                          <td className="px-5" style={{ marginLeft: "5px" }}>
                            <Text size="sm" style={{ lineHeight: 1.5 }}>
                              {moment(errand.deliveryTime).format(
                                "ddd, MM D YYYY, h:mm a"
                              )}
                            </Text>
                          </td>
                        </tr>
                        {
                          errand.completedOn ? <>
                          <tr>
                            <td>
                              <Text
                                weight={500}
                                size="sm"
                                style={{ lineHeight: 1.5 }}
                              >
                                Completed on
                              </Text>
                            </td>
                            <td className="px-5" style={{ marginLeft: "5px" }}>
                              <Text size="sm" style={{ lineHeight: 1.5 }}>
                                
                                {
                                errand.completedOn ?
                                moment(errand.completedOn).format("ddd, MM D YYYY, h:mm a")
                                : ''
                                }
                              </Text>
                            </td>
                          </tr>
                          </>
                          : <></>
                      }
                      </table>
                      <Group position="left" className="my-3">
                        <Button
                          variant="light"
                          color="blue"
                          style={{ marginTop: 14 }}
                          onClick={()=>{
                            openViewDetails(errand.errandUniqueId)
                          }}
                        >
                          View Details
                        </Button>
                      </Group>
                    </div>
                  </div>
                </Card>
              );
            }
          })
        ) : (
          <Text
            size="xl"
            weight={700}
            align={"center"}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translateX(-50%)'
            }}
            className={"text-primary"}
          >
            You Haven't Posted Any Errands Yet.
          </Text>
        )}
      </SimpleGrid>
    </>
  );
}

export default CompletedErrand;
