import { useStore } from "../store/store";
import {
  Group,
  Text,
  useMantineTheme,
  TextInput,
  Title,
  SimpleGrid,
  Image,
  Avatar,
  Indicator,
  CloseButton,
} from "@mantine/core";
import { Upload, Photo, X, Icon as TablerIcon } from "tabler-icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useErrandStore } from "../store/errand-store";
import { useEffect, useState } from "react";

function ClickAndCollect() {
  let {
    collectionOrderNumber,
    collectionOrderName,
    setCollectionNumber,
    setCollectionName,
  } = useStore();
  const theme = useMantineTheme();
  const [files, setFiles] = useState<any[]>([]);
  const { updateClickAndCollectImages } = useErrandStore();

  useEffect(()=>{
    updateClickAndCollectImages([...files])
  },[files])
  return (
    <div className="my-3">
      <div className="d-flex w-100">
        <div className="form-group col-6 p-1">
          <TextInput
            placeholder="Order Number"
            label="Order No."
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
            defaultValue={collectionOrderNumber}
            onChange={(event) => {
              setCollectionNumber(event.target.value);
            }}
          />
        </div>
        <div className="form-group col-6 p-1">
          <TextInput
            placeholder="Name On Order"
            label="Name On Order"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
            defaultValue={collectionOrderName}
            value={collectionOrderName}
            onChange={(event) => {
              setCollectionName(event.target.value);
            }}
          />
        </div>
      </div>
      <Title className="text-primary my-3" order={4}>
        Upload Images
      </Title>
      <div className="w-100">
        <div>
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={(file) => {
              setFiles([...files, file]);
            }}
          >
            <Text align="center">Drop images here</Text>
          </Dropzone>

          <SimpleGrid
            cols={4}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            mt={files.length > 0 ? "xl" : 0}
          >
            {files.map((file, index) => {
              return (
                <Group className="position-relative">
                  <CloseButton
                    className="image-close"
                    aria-label="Close modal"
                    onClick={() => {
                      let updateFileArray = files.filter((e, i) => {
                        if (index == i) {
                          return false;
                        } else {
                          return true;
                        }
                      });
                      setFiles(updateFileArray);
                    }}
                  />
                  <Image
                    width={"100%"}
                    height={150}
                    key={index}
                    src={URL.createObjectURL(new Blob(file))}
                    imageProps={{
                      onLoad: () =>
                        URL.revokeObjectURL(
                          URL.createObjectURL(new Blob(file))
                        ),
                    }}
                  />
                </Group>
              );
            })}
          </SimpleGrid>
        </div>
      </div>
    </div>
  );
}

export default ClickAndCollect;
