import create from "zustand"
import axios from 'axios';
import { showNotification } from "@mantine/notifications";
import Config from "../config/config";

const initialState = {
  invoiceList: [],
  loading: false,
  error: "",
}

const useInvoiceStore = create<any>((set: any) => ({
  invoiceList: initialState.invoiceList,
  loading: initialState.loading,
  error: initialState.error,

  fetchInvoiceList: async () => {
    set((state: any) => ({ ...state, loading: true }))
    try {
      const { data } = await axios.get(`${Config.baseUrl}/user-invoice-mapping`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, error: "", invoiceList: data}))
    } catch (error) {
      console.log(error);
      showNotification({
        id: "Invoice",
        disallowClose: true,
        autoClose: 5000,
        title: "Cannot fetch Invoices",
        message: "",
        color: "red",
        loading: false,
      });
    }
  },

}))

export default useInvoiceStore
