import { useStore } from "../store/store";

import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StepNav from "./step-nav";
import Config from "../config/config";
import { useErrandStore } from "../store/errand-store";
import { SimpleGrid, Text } from "@mantine/core";

function SelectType() {
  let navigate = useNavigate();
  const { createErrand, updateSelectType, errandDetails, setErrandSelectType } =
    useErrandStore();
  const [selectTypes, setSelectTypes] = useState([{} as any]);

  useEffect(() => {
    console.log('select-type:', errandDetails);
    // initiate Delivery Types
    let getSelectTypes = async () => {
      let { data, status } = await axios.get(
        `${Config.baseUrl}/delivery-types`
      );
      setSelectTypes(data);
    };
    getSelectTypes();
  }, []);

  // update from store
  const updateErrandStore = async () => {
    try {
      if (errandDetails.errandUniqueId) {
        await updateSelectType(
          errandDetails.selectType,
          errandDetails.errandUniqueId
        );
      } else {
        await createErrand(errandDetails.selectType);
      }
      navigate("../delivery-details");
    } catch (err) { }
  };

  return (
    <div className="mx-3 my-3">
      <SimpleGrid
        cols={2}
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: "lg" },
          { maxWidth: 755, cols: 2, spacing: "lg" },
          { maxWidth: 600, cols: 1, spacing: "sm" },
        ]}
        spacing={'lg'}
      >
        {selectTypes.map((selectTypeData, index) => {
          return (
            <div className="w-100">
              <input
                id={selectTypeData.id}
                className="opacity"
                type="radio"
                name="select-type"
                value={selectTypeData.id}
                checked={
                  errandDetails.selectType === selectTypeData.id
                    ? true
                    : false
                }
                onChange={(event) =>
                  setErrandSelectType(parseInt(event.target.value))
                }
              ></input>
              <label
                htmlFor={selectTypeData.id}
                className="select-box"
                style={{ fontSize: "1rem", textAlign: "center" }}
              >
                <img src={selectTypeData.icon} className="icon"></img>
                <div className="select-box-text">
                  <Text size="lg" weight={600} className="text-primary">{selectTypeData.name}</Text>
                  <Text size="md" className="text-primary">{selectTypeData.description}</Text>
                </div>
              </label>
            </div>
          );
        })}
      </SimpleGrid>
      <div className="my-3">
        <button
          className={`btn-primary text-decoration-none ${!errandDetails.selectType ? "disabled" : ""
            }`}
          style={{
            width: "30%",
            float: "right",
          }}
          onClick={() => {
            updateErrandStore();
          }}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
export default SelectType;
