import React from "react";
import _ from "underscore";

function Subscriptions() {

    return (
        <div className="m-3 w-100">
        </div>
    )
}

export default Subscriptions