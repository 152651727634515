import { useStore } from "../store/store";
import { useEffect, useState } from "react";
import { ToastContainer, Toast } from "react-bootstrap";

function ErrorToasts() {
  let { isError, errorObj, setErrorMessage } = useStore();
  const [message, setMessage] = useState("");
  useEffect(() => {
    if(errorObj.show){
      if (typeof errorObj.error === "string") {
        setMessage(errorObj.error);
      } else if (!Array.isArray(errorObj.error.message)) {
        setMessage(errorObj.error.message);
      } else if (Array.isArray(errorObj.error.message)) {
        setMessage(errorObj.error.message[0]);
      }
    }
  }, [errorObj]);
  return (
    <ToastContainer
      position="top-end"
      style={{ top: 0, right: 0, margin: "30px" }}
      className="p-3"
    >
      <Toast
        show={errorObj.show}
        delay={3000}
        onClose={() => {
          setErrorMessage({ isError: false, error: "", show: false });
        }}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto">
            {errorObj.isError ? "Error" : "Success"}
          </strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

export default ErrorToasts;
