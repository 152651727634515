
import create from "zustand";
import _, { omit } from 'underscore';

export const useStore = create<any>((set: any) => ({
    paymentModal:false,
    isError: false,
    errorObj: {
        isError: false,
        show: false
    },
    createErrandStep: 1,
    createErrandNav: 1,
    selectType: null,
    deliveryDetails: {},
    errandUniqueId: '',
    postageDetails: '',
    shoppingList: '',
    collectionOrderNumber: '',
    collectionOrderName: '',
    parcelDetails: {
        additionalDetails: ''
    },
    orders: [],
    customerList: [],
    registerUser: {
        isTocChecked: false,
        isPrivacyPolicyChecked: false,
        gender: 'Male',
        firstName: '',
        lastName: '',
        password: '',
        cPassword: '',
        dob: '',
        email: '',
        phoneNumber: ''
    },
    registerBusiness: {},
    enums: {},
    deliveryDetailsCompleted: false,
    setEnums: (message: any) => {
        set((state: any) => ({ ...state, enums: message }))
    },
    setErrorMessage: (object: any) => {
        set((state: any) => ({ ...state, errorObj: object}))
    },
    setCreateErrandStepNumber: (stepNumber: number) => {
        set((state: any) => ({ ...state, createErrandStep: stepNumber }))
    },
    setSelectType: (selectTypeId: number) => {
        set((state: any) => ({ ...state, selectType: selectTypeId }))
    },
    setCreateErrandNav: (id: number) => {
        set((state: any) => ({ ...state, createErrandNav: id }))
    },
    setDeliveryDetails: (data: any) => {
        set((state: any) => ({ ...state, deliveryDetails: _.extend(state.deliveryDetails, data) }))
    },
    setParcelDetails: (data: any) => {
        set((state: any) => ({ ...state, parcelDetails: _.extend(state.parcelDetails, data) }))
    },
    setErrandUniqueId: (data: string) => {
        set((state: any) => ({ ...state, errandUniqueId: data }))
    },
    setOrders: (data: Array<any>) => {
        set((state: any) => ({ ...state, orders: [...data] }))
    },
    setPostageDetails: (data: string) => {
        set((state: any) => ({ ...state, postageDetails: data }))
    },
    setShoppingList: (data: string) => {
        set((state: any) => ({ ...state, shoppingList: data }))
    },
    setCollectionNumber: (data: string) => {
        set((state: any) => ({ ...state, collectionOrderNumber: data }))
    },
    setCollectionName: (data: string) => {
        set((state: any) => ({ ...state, collectionOrderName: data }))
    },
    addCustomerList: (data: string) => {
        set((state: any) => ({ ...state, customerList: [...state.customerList, data] }))
    },
    setCustomerList: (customerList: any) => {
        set((state: any) => ({ ...state, customerList: customerList }))
    },
    setRegisterUser: (userData: any) => {
        set((state: any) => ({ ...state, registerUser: _.extend(state.registerUser, userData) }))
    },
    deleteRegisterUser: () => {
        let obj = {
            isTocChecked: false,
            isPrivacyPolicyChecked: false,
            gender: 'Male',
            firstName: '',
            lastName: '',
            password: '',
            cPassword: '',
            dob: '',
            email: '',
            phoneNumber: ''
        }
        set((state: any) => ({ ...state, registerUser: obj}))
    },
    resetRegisterBusiness: ()=>{
        set((state:any) =>({...state, registerBusiness: {}}))
    },
    resetRegisterUser: ()=>{
        set((state:any) =>({...state, registerUser: {
            isTocChecked: false,
            isPrivacyPolicyChecked: false,
            gender: 'Male',
            firstName: '',
            lastName: '',
            password: '',
            cPassword: '',
            dob: '',
            email: '',
            phoneNumber: ''
        }}))
    },
    setRegisterBusiness: (businessData: any) => {
        set((state: any) => ({ ...state, registerBusiness: _.extend(state.registerBusiness, businessData) }))
    },
    deleteRegisterBusiness: () => set((state: any) => omit(state, ['registerUser']), true),
    setDeliveryDetailsCompleted: (value: boolean) => {
        set((state: any) => ({ ...state, deliveryDetailsCompleted: value }))
    },
    togglePaymentModal: () => {
        set((state: any) => ({ ...state, paymentModal: !state.paymentModal }))
    }
}))
