import { useStore } from "../store/store";
import React, { useEffect } from "react";
import { useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import DeliveryDetails from "./delivery-details";
import ParcelDetails from "./parcel-details";
import SelectType from "./select-type.component";
import Summary from "./summary.component";
import { useErrandStore } from "../store/errand-store";
import StepNav from "./step-nav";
import axios from "axios";
import Config from "../config/config";
import AxiosInstance from "../utils/axios-instance";

function CreateErrand() {
  const { createErrandNav, setCreateErrandNav } = useStore();
  const { fetchErrandDetails, errandDetails, resetErrandStore } = useErrandStore();
  const location = useLocation();
  let navigate = useNavigate();
  React.useEffect(() => {
    // runs on location, i.e. route, change
    let path = location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
    if (path == "select-type") {
      setCreateErrandNav(1);
    } else if (path == "delivery-details") {
      setCreateErrandNav(2);
    } else if (path == "parcel-details") {
      setCreateErrandNav(3);
    } else if (path == 'summary') {
      setCreateErrandNav(4);
    } else {
      setCreateErrandNav(1);
    }
  }, [location]);

  useEffect(() => {
    // fetch user and initiate intercom
    const fetchUserDetails = async () => {
      try {
        let { data, status } = await AxiosInstance.get(
          `${Config.baseUrl}/users/currentUserDetails`
        );
        window.Intercom("boot", {
          api_base: "https://api-iam.intercom.io",
          app_id: "unzba7n1",
          name:
            data.lastName && data.lastName !== ""
              ? data.firstName + " " + data.lastName
              : data.firstName, // Full name
          email: data.email, // Email address
          user_id: data.id + "-" + data.email,
        });
        window.Intercom("update");
      } catch (err) {
        console.log(err);
      }
    };
    fetchUserDetails();
  }, []);
  return (
    <div>
      {/* {createErrandNav !== 4 ? (
        <>
          <div className="px-4">
            <h3 className="text-danger">Step {createErrandNav}/3</h3>
            <hr></hr>
          </div>
        </>
      ) : (
        <></>
      )} */}
      <div className="create-errand-nav">
        {createErrandNav !== 4 ? <StepNav /> : <></>}
        <Routes>
          <Route path="select-type" element={<SelectType />} />
          <Route path="delivery-details" element={<DeliveryDetails />} />
          <Route path="parcel-details" element={<ParcelDetails />} />
          <Route path="summary" element={<Summary />} />
          <Route path="*" element={<SelectType />} />
        </Routes>
      </div>
    </div>
  );
}

export default CreateErrand;
