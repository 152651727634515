import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import StepNav from "./step-nav";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import { At, Calendar, Journal, Search } from "react-bootstrap-icons";
import _, { pick } from "underscore";
import ContactModal from "./contact-modal.component";
import {
  Autocomplete,
  Avatar,
  Group,
  Input,
  Select,
  SimpleGrid,
  Switch,
  Text,
  TextInput,
} from "@mantine/core";
import { useErrandStore } from "../store/errand-store";
import { showNotification } from "@mantine/notifications";
import { useModals } from "@mantine/modals";
import { useForceUpdate } from "@mantine/hooks";

function DeliveryDetails() {
  let navigate = useNavigate();

  const MINUTE_DIFF = 30;
  const { errandDetails, updateDeliveryDetails } = useErrandStore();
  const { addCustomerList, customerList } = useStore();
  const [senderNumberMand, setSendNumberMand] = useState(true);
  const [receiverNumberMand, setReceiverNumberMand] = useState(true);

  const [pickUp, setPickUp] = React.useState({
    id: null,
    street: "",
    datetime: moment().add(MINUTE_DIFF, "minutes").toDate(),
    postcode: "",
    lat: "",
    long: "",
    senderContactNumber: "",
    name: "",
    flat: "",
  });

  const [deliverTo, setDeliverTo] = useState({
    id: null,
    street: "",
    datetime: moment()
      .add(MINUTE_DIFF * 2, "minutes")
      .toDate(),
    postcode: "",
    lat: "",
    long: "",
    receiverContactNumber: "",
    name: "",
    flat: "",
  });

  useEffect(() => {
    const getContacts = async () => {
      try {
        let { data, status } = await axios.get(`${Config.baseUrl}/contacts`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        data[0].contactUsers.forEach((user: any) => {
          if (user.isActive) {
            if (!_.pluck(customerList, "id").includes(user.id)) {
              addCustomerList({
                id: user.id,
                fName: user.firstName,
                lName: user.lastName,
                contactNumber: user.contactNumber,
                address: user.contactLocations,
              });
            }
          }
        });
      } catch (err: any) {
        console.log(err);
      }
    };
    getContacts();
  }, []);

  useEffect(() => {
    if (errandDetails.selectType === 2 || errandDetails.selectType === 3) {
      setSendNumberMand(false);
    }
    if (errandDetails.selectType === 4) {
      setReceiverNumberMand(false);
    }
    if (!errandDetails.errandUniqueId) {
      navigate("../select-type");
    }
    if (errandDetails.pickUp) {
      // pickUp
      setPickUp(errandDetails.pickUp);
    }
    if (errandDetails.drop) {
      // deliverTo
      setDeliverTo(errandDetails.drop);
    }
  }, []);

  const [disabled, setDisabled] = useState(true);
  const [disablePickUpPostcode, setDisablePPostCode] = useState(false);
  const [disableDeliverPostcode, setDisableDPostCode] = useState(false);
  const [disablePAddToContact, setDisablePAddToContact] = useState(false);
  const [disableDAddToContact, setDisableDAddToContact] = useState(false);
  // contact modal
  const [selectFilterValue, setSelectFilterValue] = useState("");
  //contact modal
  const modals = useModals();
  const forceUpdate = useForceUpdate();

  interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
    firstName: string;
    lastName: string;
    contactNumber: string;
    contactLocations: Array<any>;
    modalValue: number;
  }

  const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
    (
      {
        firstName,
        lastName,
        contactNumber,
        contactLocations,
        modalValue,
        ...others
      }: ItemProps,
      ref
    ) => (
      <div
        ref={ref}
        {...others}
        onClick={() => {
          if (modalValue === 1) {
            setPickUp({
              ...pickUp,
              street: contactLocations[0].street || "",
              postcode: contactLocations[0].postcode || "",
              flat: contactLocations[0].flat || "",
              senderContactNumber: contactNumber || "",
              name: lastName ? firstName + " " + lastName : firstName,
            });
          } else {
            setDeliverTo({
              ...deliverTo,
              street: contactLocations[0].street || "",
              postcode: contactLocations[0].postcode || "",
              flat: contactLocations[0].flat || "",
              receiverContactNumber: contactNumber || "",
              name: lastName ? firstName + " " + lastName : firstName,
            });
          }
          modals.closeAll();
        }}
      >
        <Group noWrap>
          <div>
            <Text size="sm">
              {lastName ? firstName + " " + lastName : firstName}
            </Text>
            <Text size="xs" color="dimmed">
              {contactLocations && contactLocations[0]
                ? !contactLocations[0].flat
                  ? "" +
                    contactLocations[0].street +
                    "," +
                    contactLocations[0].postcode
                  : contactLocations[0].flat +
                    "," +
                    contactLocations[0].street +
                    "," +
                    contactLocations[0].postcode
                : ""}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const openContactModal = async (modalType: number) => {
    // fetch contact list
    try {
      // const selectRef = useRef<HTMLInputElement>();
      let { data, status } = await axios.get(
        `${Config.baseUrl}/business/fetchBusinessAddress`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      var newData = data.map((element) => {
        element["modalValue"] = modalType;
        return element;
      });
      const id = modals.openModal({
        title: "Customer List",
        children: (
          <>
            <Select
              label="Choose Customer Address"
              placeholder="Pick one"
              value={selectFilterValue}
              itemComponent={SelectItem}
              onChange={(e: any) => {
                setSelectFilterValue(e.target.value);
              }}
              data={newData}
              searchable
              maxDropdownHeight={400}
              nothingFound="No Contacts"
              filter={(value, item) => {
                if (item) {
                  return item.firstName
                    .toLowerCase()
                    .includes(value.toLowerCase().trim());
                } else {
                  return false;
                }
              }}
            />
          </>
        ),
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (
      pickUp.postcode &&
      pickUp.name &&
      pickUp.datetime &&
      deliverTo.street &&
      deliverTo.postcode &&
      deliverTo.name &&
      deliverTo.datetime &&
      deliverTo.street &&
      moment(pickUp.datetime).isAfter(moment()) &&
      moment(deliverTo.datetime).isSameOrAfter(
        moment(pickUp.datetime).add(MINUTE_DIFF, "minutes")
      )
    ) {
      if (deliverTo.lat && deliverTo.long && pickUp.lat && pickUp.long) {
        if (deliverTo.lat == pickUp.lat && deliverTo.long == pickUp.long) {
          setDisabled(true);
        } else {
          setDisabled(false);
        }
      } else {
        setDisabled(false);
      }
      // check mandatory fields
      if (receiverNumberMand) {
        // check if number is entered or not
        if (
          deliverTo.receiverContactNumber &&
          deliverTo.receiverContactNumber.length > 7
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
      if (senderNumberMand) {
        // check if number is entered or not
        if (
          
          pickUp.senderContactNumber &&
          pickUp.senderContactNumber.length > 7
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
      if (receiverNumberMand && senderNumberMand) {
        if (
          pickUp.senderContactNumber &&
          deliverTo.receiverContactNumber &&
          pickUp.senderContactNumber.length > 7 &&
          deliverTo.receiverContactNumber.length > 7
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } else {
      setDisabled(true);
    }
  }, [pickUp, deliverTo]);

  const updateErrandDetails = async () => {
    let response = await updateDeliveryDetails(
      pickUp,
      deliverTo,
      errandDetails.errandUniqueId
    );
    if (response) {
      navigate(`../parcel-details`);
    }
  };

  const addToCustomerList = async (type: number) => {
    let contactDetails: any;
    try {
      if (type === 1) {
        contactDetails = {
          fName: pickUp.name || "",
          street: pickUp.street || "",
          flatNumber: pickUp.flat || "",
          postcode: pickUp.postcode || "",
          number: pickUp.senderContactNumber || "",
        };
      } else {
        contactDetails = {
          fName: deliverTo.name || "",
          street: deliverTo.street || "",
          flatNumber: deliverTo.flat || "",
          postcode: deliverTo.postcode || "",
          number: deliverTo.receiverContactNumber || "",
        };
      }
      let { data, status } = await axios.post(
        `${Config.baseUrl}/contacts`,
        { ...contactDetails },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (type === 1) {
        setDisablePAddToContact(true);
      } else {
        setDisableDAddToContact(true);
      }
    } catch (error: any) {
      showNotification({
        id: "errand-delivery-details-update",
        disallowClose: true,
        autoClose: 5000,
        title: "Errand",
        message: "Contacts was not added",
        color: "green",
        loading: false,
      });
    }
  };

  const [popoverAddress, setPopoverAddress] = useState<Array<any>>([]);
  const [popoverDAddress, setPopoverDAddress] = useState<Array<any>>([]);
  const [deliverySearchValue, setDeliverySearchValue] = useState("");
  const [pickupSearchValue, setPickUpSearchValue] = useState("");

  const populateAddress = async (term: string, type = "pickup") => {
    setPopoverAddress([]);
    let { data, status } = await axios.get(
      `https://api.getAddress.io/autocomplete/${term}?api-key=${"sbOTMvyXzUqCCiKx__9PRA35300"}&all=true&top=20&filter:{"town_or_city":"London | Bristol"}`
    );
    if (type === "pickup") {
      setPopoverAddress(
        data.suggestions.map((e: any) => {
          e["value"] = e.address;
          return e;
        })
      );
    } else {
      setPopoverDAddress(
        data.suggestions.map((e: any) => {
          e["value"] = e.address;
          return e;
        })
      );
    }
  };

  const autoFillAddress = async (id: string, isPickup = true) => {
    let { data, status } = await axios.get(
      `https://api.getAddress.io/get/${id}?api-key=${
        process.env.GET_ADDR_KEY || "sbOTMvyXzUqCCiKx__9PRA35300"
      }` // @TODO remove
    );
    if (isPickup) {
      if (
        deliverTo.lat === data.latitude &&
        deliverTo.long === data.longitude
      ) {
        setDisabled(true);
        // delivery and pickup address cannot be same
        showNotification({
          id: "location",
          disallowClose: true,
          autoClose: 5000,
          title: "Pick up location cannot be same as delivery location",
          message: "",
          color: "red",
          loading: false,
        });
        return false;
      }

      let pickUpObj = {
        buildingName: data.building_name,
        postcode: data.postcode,
        flat: data.sub_building_name,
        street: `${data.line_1}${data.line_2 ? "," + data.line_2 : ""}${
          data.line_3 ? "," + data.line_3 : ""
        }`,
        lat: "" + data.latitude,
        long: "" + data.longitude,
        name: pickUp.name,
        datetime: pickUp.datetime,
      };
      setPickUp({ ...pickUp, ...pickUpObj });
      // setDisablePPostCode(true)
    } else {
      if (pickUp.lat == data.latitude && pickUp.long == data.longitude) {
        setDisabled(true);
        // delivery and pickup address cannot be same
        showNotification({
          id: "location",
          disallowClose: true,
          autoClose: 5000,
          title: "Delivery location cannot be same as pick up location",
          message: "",
          color: "red",
          loading: false,
        });
        return false;
      }
      let deliverObj = _.extend(deliverTo, {
        buildingName: data.building_name,
        postcode: data.postcode,
        flat: data.sub_building_name,
        street: `${data.line_1}${data.line_2 ? "," + data.line_2 : ""}${
          data.line_3 ? "," + data.line_3 : ""
        }`,
        lat: data.latitude,
        long: data.longitude,
      });
      setDeliverTo({ ...deliverTo, ...deliverObj });
      // setDisableDPostCode(true)
    }
  };

  return (
    <div className="mx-3 my-3">
      <div className="row">
        <h5
          className="text-danger"
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Pick Up From
        </h5>
        <div className="row">
          <div className="form-group col-11">
            <Autocomplete
              styles={{
                dropdown: {
                  height: "15rem",
                  overflowY: "scroll",
                },
              }}
              value={deliverySearchValue}
              placeholder="Enter your postcode"
              icon={<Search size={16} />}
              limit={100}
              filter={(value, item) => true} // to get all the data
              onChange={(value) => {
                populateAddress(value);
                setDeliverySearchValue(value);
              }}
              onItemSubmit={(value) => {
                autoFillAddress(value.id);
              }}
              data={popoverAddress}
            ></Autocomplete>
          </div>
          <div className="col-1">
            <div className="address-book">
              <svg
                width="27"
                height="30"
                viewBox="0 0 27 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  openContactModal(1);
                }}
              >
                <g clip-path="url(#clip0_388_194)">
                  <path
                    d="M25.5469 9.375C25.9336 9.375 26.25 9.05859 26.25 8.67188V6.32812C26.25 5.94141 25.9336 5.625 25.5469 5.625H24.375V2.8125C24.375 1.25977 23.1152 0 21.5625 0H2.8125C1.25977 0 0 1.25977 0 2.8125V27.1875C0 28.7402 1.25977 30 2.8125 30H21.5625C23.1152 30 24.375 28.7402 24.375 27.1875V24.375H25.5469C25.9336 24.375 26.25 24.0586 26.25 23.6719V21.3281C26.25 20.9414 25.9336 20.625 25.5469 20.625H24.375V16.875H25.5469C25.9336 16.875 26.25 16.5586 26.25 16.1719V13.8281C26.25 13.4414 25.9336 13.125 25.5469 13.125H24.375V9.375H25.5469ZM12.1875 7.5C14.2559 7.5 15.9375 9.18164 15.9375 11.25C15.9375 13.3184 14.2559 15 12.1875 15C10.1191 15 8.4375 13.3184 8.4375 11.25C8.4375 9.18164 10.1191 7.5 12.1875 7.5ZM18.75 21.375C18.75 21.9961 18.1641 22.5 17.4375 22.5H6.9375C6.21094 22.5 5.625 21.9961 5.625 21.375V20.25C5.625 18.3867 7.38867 16.875 9.5625 16.875H9.85547C10.5762 17.1738 11.3613 17.3438 12.1875 17.3438C13.0137 17.3438 13.8047 17.1738 14.5195 16.875H14.8125C16.9863 16.875 18.75 18.3867 18.75 20.25V21.375Z"
                    fill="#E33960"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_388_194">
                    <rect width="26.25" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <SimpleGrid className="my-3" cols={2} spacing={"md"}>
          <TextInput
            placeholder="Street And Number"
            width={50}
            defaultValue={pickUp.street}
            value={pickUp.street}
            onChange={(event: any) => {
              setPickUp({
                ...pickUp,
                street: event.target.value,
              });
            }}
            label="Street And Number"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
          />
          <TextInput
            placeholder="Flat No."
            defaultValue={pickUp.flat}
            value={pickUp.flat}
            onChange={(event: any) => {
              setPickUp({ ...pickUp, flat: event.target.value });
            }}
            label="Flat No."
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <TextInput
            placeholder="Postcode"
            disabled={disablePickUpPostcode}
            defaultValue={pickUp.postcode}
            value={pickUp.postcode}
            onChange={(event: any) => {
              setPickUp({
                ...pickUp,
                postcode: event.target.value,
                lat: "",
                long: "",
              });
            }}
            required
            label="Postcode"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <TextInput
            placeholder="Sender Number"
            defaultValue={pickUp.senderContactNumber}
            value={pickUp.senderContactNumber}
            onChange={(event: any) => {
              setPickUp({ ...pickUp, senderContactNumber: event.target.value });
            }}
            required={senderNumberMand}
            maxLength={50}
            label="Sender Number"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
          ></TextInput>
          <TextInput
            placeholder="Sender Name"
            defaultValue={pickUp.name || ""}
            value={pickUp.name || ""}
            onChange={(event: any) => {
              setPickUp({ ...pickUp, name: event.target.value });
            }}
            required
            label="Sender Name"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
          />
          <div className="w-100">
            <label
              id="time"
              className="primary form-label m-0"
              style={{ fontSize: "14px" }}
            >
              Pickup Date
            </label>
            <DateTimePicker
              onChange={(data: any) => {
                setPickUp({ ...pickUp, datetime: data });
                setDeliverTo({
                  ...deliverTo,
                  datetime: moment(data).add(MINUTE_DIFF, "minutes").toDate(),
                });
              }}
              clearIcon={null}
              value={pickUp.datetime}
              format={"dd.MM.yyyy HH:mm"}
              minDate={moment().toDate()}
              calendarIcon={
                <Calendar className="text-pink" size={20}></Calendar>
              }
              className="date-primary-timepicker w-100"
            ></DateTimePicker>
          </div>
          <Switch
            label="Add to customer list"
            classNames={{ label: "text-primary montserrat" }}
            checked={disablePAddToContact}
            onChange={(event) => {
              if (event.currentTarget.checked && !disablePAddToContact) {
                addToCustomerList(1);
              } else {
                null;
              }
            }}
          />
        </SimpleGrid>
      </div>

      <div className="row mt-1">
        <h5
          className="text-danger"
          style={{ fontWeight: "bold", fontSize: "16px" }}
        >
          Deliver To
        </h5>
        <div className="row">
          <div className="form-group col-11">
            <Autocomplete
              styles={{
                dropdown: {
                  height: "15rem",
                  overflowY: "scroll",
                },
              }}
              value={pickupSearchValue}
              placeholder="Enter your postcode"
              icon={<Search size={16} />}
              limit={100}
              filter={(value, item) => true} // to get all the data
              onChange={(value) => {
                populateAddress(value, "delivery");
                setPickUpSearchValue(value);
              }}
              onItemSubmit={(value) => {
                autoFillAddress(value.id, false);
              }}
              data={popoverDAddress}
            ></Autocomplete>
          </div>
          <div className="col-1">
            <div className="address-book">
              <svg
                width="27"
                height="30"
                viewBox="0 0 27 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                  openContactModal(2);
                }}
              >
                <g clip-path="url(#clip0_388_194)">
                  <path
                    d="M25.5469 9.375C25.9336 9.375 26.25 9.05859 26.25 8.67188V6.32812C26.25 5.94141 25.9336 5.625 25.5469 5.625H24.375V2.8125C24.375 1.25977 23.1152 0 21.5625 0H2.8125C1.25977 0 0 1.25977 0 2.8125V27.1875C0 28.7402 1.25977 30 2.8125 30H21.5625C23.1152 30 24.375 28.7402 24.375 27.1875V24.375H25.5469C25.9336 24.375 26.25 24.0586 26.25 23.6719V21.3281C26.25 20.9414 25.9336 20.625 25.5469 20.625H24.375V16.875H25.5469C25.9336 16.875 26.25 16.5586 26.25 16.1719V13.8281C26.25 13.4414 25.9336 13.125 25.5469 13.125H24.375V9.375H25.5469ZM12.1875 7.5C14.2559 7.5 15.9375 9.18164 15.9375 11.25C15.9375 13.3184 14.2559 15 12.1875 15C10.1191 15 8.4375 13.3184 8.4375 11.25C8.4375 9.18164 10.1191 7.5 12.1875 7.5ZM18.75 21.375C18.75 21.9961 18.1641 22.5 17.4375 22.5H6.9375C6.21094 22.5 5.625 21.9961 5.625 21.375V20.25C5.625 18.3867 7.38867 16.875 9.5625 16.875H9.85547C10.5762 17.1738 11.3613 17.3438 12.1875 17.3438C13.0137 17.3438 13.8047 17.1738 14.5195 16.875H14.8125C16.9863 16.875 18.75 18.3867 18.75 20.25V21.375Z"
                    fill="#E33960"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_388_194">
                    <rect width="26.25" height="30" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <SimpleGrid className="my-3" cols={2} spacing={"md"}>
          <TextInput
            placeholder="Street And Number"
            width={50}
            defaultValue={deliverTo.street}
            value={deliverTo.street}
            onChange={(event: any) => {
              setDeliverTo({ ...deliverTo, street: event.target.value });
            }}
            label="Street And Number"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
          />
          <TextInput
            placeholder="Flat No."
            defaultValue={deliverTo.flat}
            value={deliverTo.flat}
            onChange={(event: any) => {
              setDeliverTo({ ...deliverTo, flat: event.target.value });
            }}
            label="Flat No."
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <TextInput
            placeholder="Postcode"
            defaultValue={deliverTo.postcode}
            value={deliverTo.postcode}
            disabled={disableDeliverPostcode}
            onChange={(event: any) => {
              setDeliverTo({
                ...deliverTo,
                postcode: event.target.value,
                lat: "",
                long: "",
              });
            }}
            required
            label="Postcode"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <TextInput
            placeholder="Recipient Number"
            defaultValue={deliverTo.receiverContactNumber}
            value={deliverTo.receiverContactNumber}
            required={receiverNumberMand}
            maxLength={50}
            onChange={(event: any) => {
              setDeliverTo({
                ...deliverTo,
                receiverContactNumber: event.target.value,
              });
            }}
            label="Recipient Number"
            // icon={
            //   <TextInput
            //     classNames={{ input: "p-1 wx-5" }}
            //     value="+44"
            //     disabled
            //   />
            // }
            classNames={{
              label: "text-primary font-weight-bold",
            }}
          />
          <TextInput
            placeholder="Recipient Name"
            defaultValue={deliverTo.name}
            value={deliverTo.name}
            onChange={(event: any) => {
              setDeliverTo({ ...deliverTo, name: event.target.value });
            }}
            required
            label="Recipient Name"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
          />
          <div className="w-100">
            <label
              id="time"
              className="primary form-label m-0"
              style={{ fontSize: "14px" }}
            >
              Delivery Date
            </label>
            <DateTimePicker
              onChange={(data: any) => {
                if (moment(data).isBefore(moment(pickUp.datetime))) {
                  showNotification({
                    id: "datetime",
                    disallowClose: true,
                    autoClose: 5000,
                    title: "Delivery date cannot be before pick up date",
                    message: "",
                    color: "red",
                    loading: false,
                  });
                  return false;
                }
                if (
                  moment(data).format("YYYY-MM-DD") !==
                  moment(pickUp.datetime).format("YYYY-MM-DD")
                ) {
                  showNotification({
                    id: "datetime",
                    disallowClose: true,
                    autoClose: 5000,
                    title:
                      "Delivery date and pick up date should be on same day.",
                    message: "",
                    color: "red",
                    loading: false,
                  });
                  return false;
                }
                setDeliverTo({ ...deliverTo, datetime: data });
              }}
              clearIcon={null}
              value={deliverTo.datetime}
              format={"dd.MM.yyyy HH:mm"}
              minDate={moment().toDate()}
              calendarIcon={
                <Calendar className="text-pink" size={20}></Calendar>
              }
              className="date-primary-timepicker w-100"
            ></DateTimePicker>
          </div>
          <Switch
            label="Add to customer list"
            classNames={{ label: "text-primary montserrat" }}
            checked={disableDAddToContact}
            onChange={(event) => {
              if (event.currentTarget.checked && !disableDAddToContact) {
                addToCustomerList(2);
              } else {
                null;
              }
            }}
          />
        </SimpleGrid>
      </div>
      <div className="row mt-1">
        <div className="col-4 offset-8 p-0">
          <button
            className={`btn-primary text-decoration-none w-50 ${
              disabled ? "disabled" : ""
            }`}
            style={{
              textAlign: "center",
              padding: "10px",
              width: "30%",
              float: "right",
            }}
            onClick={() => {
              updateErrandDetails();
            }}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
export default DeliveryDetails;
