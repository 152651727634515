import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "underscore";
import ClickAndCollect from "./click-and-collect.component";
import LocalShopping from "./local-shopping.component";
import Postage from "./postage.component";
import StepNav from "./step-nav";
import { useErrandStore } from "../store/errand-store";
import {
  Card,
  SegmentedControl,
  Select,
  SimpleGrid,
  Image,
  Button,
  Overlay,
  Title, Text
} from "@mantine/core";

function ParcelDetails() {
  let navigate = useNavigate();
  let {
    parcelDetails,
    setParcelDetails,
    errandUniqueId,
    selectType,
    postageDetails,
    shoppingList,
    collectionOrderNumber,
    collectionOrderName,
    enums,
    setEnums,
  } = useStore();
  const [deliverySizes, setDeliverySizes] = useState([] as Array<any>);
  const [deliveryWeights, setDeliveryWeights] = useState([] as Array<any>);
  const [deliveryType, setDeliveryType] = useState("");
  const { errandDetails, clickAndCollectImagesArray, localPostageImagesArray } = useErrandStore();
  const [priority, setPriority] = useState(2);
  const [parcelBtnDisabled, setParcelBtnDisabled] = useState(false);

  const disableIncompatibleWeights = (sizeId: number) => {
    let size = deliverySizes.find((e) => e.id === sizeId);
    deliveryWeights.forEach((weight) => {
      if (
        size &&
        size.compatibleWeights &&
        size.compatibleWeights.includes(weight.id)
      ) {
        weight["isDisabled"] = false;
      } else {
        weight["isDisabled"] = true;
      }
    });
    setParcelDetails({ weight: null });
  };

  useEffect(() => {
    disableIncompatibleWeights(parcelDetails.size);
  }, [deliveryWeights]);

  useEffect(() => {
    let getEnums = async () => {
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }
    };
    getEnums();
  }, []);

  useEffect(() => {
    let getBaseData = async () => {
      {
        let { data, status } = await axios.get(
          `${Config.baseUrl}/delivery-size`
        );
        if (data) {
          console.log(data);
          setDeliverySizes(data);
        }
      }
      {
        let { data, status } = await axios.get(
          `${Config.baseUrl}/delivery-weights`
        );
        if (data) {
          setDeliveryWeights(data);
        }
      }
    };
    getBaseData();
  }, []);

  const updateErrandDetails = async () => {
    let obj: any = {};
    if (postageDetails) {
      obj.postageDetails = postageDetails;
    } else if (shoppingList && errandDetails.selectType === 3) {
      obj.shoppingList = shoppingList;
    } else if (errandDetails.selectType == 2) {
      obj.collectionErrandName = collectionOrderName;
      obj.collectionErrandNumber = collectionOrderNumber;
    }
    let imageUpload = false;
    let uploadResponse;
    // first upload images depending on the type
  
    if (errandDetails.selectType == 2) {
      imageUpload = true;
      const formData = new FormData();
      for (let index = 0; index < clickAndCollectImagesArray.length; index++) {
        const fileElement = clickAndCollectImagesArray[index];
        formData.append(`clickAndCollect`, fileElement[0]);
      }

      uploadResponse = await axios.post(
        `${Config.baseUrl}/errand/clickAndCollect/fileUpload?errandUniqueId=${errandDetails.errandUniqueId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } else if (errandDetails.selectType == 3) {
      imageUpload = true;
      const formData = new FormData();
      for (let index = 0; index < localPostageImagesArray.length; index++) {
        const fileElement = localPostageImagesArray[index];
        formData.append(`localShopping`, fileElement[0]);
      }

      if (localPostageImagesArray.length) {
        uploadResponse = await axios.post(
          `${Config.baseUrl}/errand/localShopping/fileUpload?errandUniqueId=${errandDetails.errandUniqueId}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
      } else {
        imageUpload = false;
      }
    }
    if (imageUpload && uploadResponse.data.success) {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/errand/updateErrand`,
        {
          deliverySize: parcelDetails.size,
          deliveryWeight: parcelDetails.weight,
          additionalDetails: parcelDetails.additionalDetails,
          errandUniqueId: errandDetails.errandUniqueId,
          priority: priority,
          ...obj,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (status === 201) {
        localStorage.setItem("errandUniqueId", errandDetails.errandUniqueId);
        navigate("../summary");
      }
    } else if (!imageUpload) {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/errand/updateErrand`,
        {
          deliverySize: parcelDetails.size,
          deliveryWeight: parcelDetails.weight,
          additionalDetails: parcelDetails.additionalDetails,
          errandUniqueId: errandDetails.errandUniqueId,
          priority: priority,
          ...obj,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (status === 201) {
        localStorage.setItem("errandUniqueId", errandDetails.errandUniqueId);
        navigate("../summary");
      }
    }
  };
  
  return (
    <div className="m-3">
      <Title className='text-primary my-3' order={4}>Approximate Size</Title>
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 3, spacing: "sm" },
          { maxWidth: 600, cols: 2, spacing: "xs" },
        ]}
        spacing={"md"}
      >
        {deliverySizes.map((deliverySize) => {
          return (
            <div style={{ width: 150, height: 120 }}>
              <input
                id={"ds" + deliverySize.id}
                className="opacity"
                type="radio"
                name="size"
                value={deliverySize.id}
                checked={
                  parcelDetails.size === deliverySize.id ? true : false
                }
                onChange={(event) => {
                  disableIncompatibleWeights(parseInt(event.target.value));
                  setParcelDetails({ size: parseInt(event.target.value) });
                }}
              ></input>
              <label
                htmlFor={"ds" + deliverySize.id}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  height: "inherit",
                  width: "inherit",
                }}
              >
                <Card
                  shadow="lg"
                  p="lg"
                  style={{ height: "100%", width: "100%", padding: "5px" }}
                >
                  <Card.Section>
                    <img
                      src={deliverySize.icon}
                      style={{
                        width: "70px",
                        height: "90px",
                        margin: "0 auto",
                        marginTop: "10px",
                      }}
                    />
                  </Card.Section>
                  <Text weight={600} className="text-blue">
                    {deliverySize.name}
                  </Text>
                </Card>
              </label>
            </div>
          );
        })}
      </SimpleGrid>
      <Title className='text-primary my-3' order={4}>Approximate Weight</Title>
      <SimpleGrid
        breakpoints={[
          { maxWidth: 980, cols: 3, spacing: "md" },
          { maxWidth: 755, cols: 3, spacing: "sm" },
          { maxWidth: 600, cols: 2, spacing: "xs" },
        ]}
        cols={5}
        spacing={"md"}
      >
        {deliveryWeights.map((deliveryWeight) => {
          return (
            <div style={{ width:150, height: 120 }}>
              <input
                id={"dw" + deliveryWeight.id}
                className="opacity"
                type="radio"
                name="weight"
                value={deliveryWeight.id}
                checked={
                  parcelDetails.weight === deliveryWeight.id ? true : false
                }
                onChange={(event) =>
                  setParcelDetails({
                    weight: parseInt(event.target.value),
                  })
                }
              ></input>
              <label
                htmlFor={"dw" + deliveryWeight.id}
                style={{
                  fontSize: "16px",
                  textAlign: "center",
                  height: "inherit",
                  width: "inherit",
                }}
              >
                <Card
                  shadow="lg"
                  p="lg"
                  style={{ height: "100%", width: "100%", padding: "10px" }}
                >
                  {deliveryWeight.isDisabled && (
                    <Overlay opacity={0.3} color="#000" zIndex={5} />
                  )}
                  <Card.Section style={{ marginTop: "-20px" }}>
                    <img
                      src={deliveryWeight.icon}
                      style={{
                        width: "70px",
                        height: "90px",
                        margin: "0 auto",
                        marginTop: "10px",
                      }}
                    />
                  </Card.Section>
                  <Text weight={600} className="text-blue">
                    {deliveryWeight.name}
                  </Text>
                </Card>
              </label>
            </div>
          );
        })}
      </SimpleGrid>
      {errandDetails.selectType === 3 ? (
        <LocalShopping />
      ) : errandDetails.selectType === 2 ? (
        <ClickAndCollect />
      ) : errandDetails.selectType === 4 ? (
        <Postage />
      ) : (
        <></>
      )}
      <div className="parcel-details-text-area">
        <Title className='text-primary my-3' order={4}>Describe your parcel:</Title>
        <textarea
          className="w-100 primary"
          placeholder="Be as descriptive and detailed as possible. Such as fragile, handle with care, and delicate flowers.
            This allows us to find the best runner for your errand. "
          value={parcelDetails.additionalDetails}
          name="parcel"
          onChange={(event) =>
            setParcelDetails({ additionalDetails: event.target.value })
          }
        ></textarea>
      </div>
      <Title className='text-primary my-3' order={4}>Priority</Title>
      <div className="parcel-details-text-area">
          {enums && enums.ERRAND_PRIORITY ? (
            <SegmentedControl
              fullWidth
              orientation="vertical"
              onChange={(data) => {
                setPriority(parseInt(data));
              }}
              data={Object.keys(enums.ERRAND_PRIORITY).map((key) => {
                return {
                  value: enums.ERRAND_PRIORITY[key].value,
                  label: enums.ERRAND_PRIORITY[key].label,
                };
              })}
            />
          ) : (
            <></>
          )}
      </div>
      <div className="my-3">
        <button
          className={`btn-primary text-decoration-none ${!parcelDetails.weight ? "disabled" : ""
            }`}
          style={{
            width: "40%",
            float: "right",
          }}
          onClick={() => {
            updateErrandDetails();
          }}
        >
          Create Errand
        </button>
      </div>
    </div>
  );
}

export default ParcelDetails;
