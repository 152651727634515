import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";

function PhoneVerified() {
  const [email, setEmail] = useState("");
  let navigate = useNavigate();
  
  useEffect(() => {    
    let email = localStorage.getItem("email");
    localStorage.setItem("email",'');
    setEmail(email || "");
  }, []);

  return (
    <>
      <div
        className="container w-75 d-flex flex-column gap-3 email-container"
        style={{ position: "relative", bottom: "100%" }}
      >
        <br></br>

        <div className="h3 text-primary text-center">Congratulations !! </div>
        <div className="h5 text-primary text-center">
          Your contact number has been verified successfully. 
          Now, its time to verify you email : {email}
        </div>

        <div className="row justify-content-center text-center">
          <div className="col-6">
            <button
              className="btn-primary text-decoration-none email-verification-button"
              type="submit"
              onClick={() => {
                navigate("/login");
              }}
            >
              Go back to Login
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PhoneVerified;
