import { useAppStore } from "../store/app-store";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import Config from "../config/config";
import { showNotification } from "@mantine/notifications";

export const ProtectedRoute = ({ children }: any) => {
  let navigate = useNavigate();
  const { isLoggedIn, setLoggedIn, logout } = useAppStore();
  useEffect(() => {
    const validateToken = async () => {
      let token = localStorage.getItem("token");
      let isVerified = localStorage.getItem("isVerified");
      if(isVerified === 'false'){
        navigate("/hold");
      }else {
        if (token) {
          try{
              // check if token is valid
          let {data, status} = await axios.get(`${Config.baseUrl}/validateToken`, {
              headers: { Authorization: `Bearer ${token}` }
          })
          }catch(err){
              // need to refresh Token here
              showNotification({
                  id: "Token expired",
                  disallowClose: true,
                  autoClose: 1500,
                  title: "User Authentication",
                  message: 'Token Expired',
                  color: "red",
                  loading: false,
                })
              logout()
              navigate("/");    
          }
          setLoggedIn(true);
        } else {
          navigate("/");
        }
      }
    };
    validateToken();
  }, [navigate]);
  return children;
};
