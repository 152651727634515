
import create from "zustand";
import _, { object, omit } from 'underscore';
import AxiosInstance from "../utils/axios-instance"
import Config from "../config/config";


const initialState = {
    globalEnums: {},
    countryCodes: [],
    countryOptions: [],
}

export const useGlobalEnumsStore = create<any>((set: any) => ({

    globalEnums: initialState.globalEnums,
    countryOptions: initialState.countryOptions,

    setCountryOptions: (object: any) => {
        set((state: any) => ({ ...state, countryOptions: object }))
    },

    fetchGlobalEnums: async () => {
        try {
            const { data } = await AxiosInstance.get(`${Config.baseUrl}/globalEnums/`)
            set((state: any) => ({ ...state, error: "", globalEnums: data }))
            return data
        } catch (err: any) {
            let message = 'Cannot fetch the global enums';
            if (Array.isArray(err.response.data.message)) {
                message = err.response.data.message[0]
            } else {
                message = err.response.data.message
            }
            set((state: any) => ({
                ...state, errAlertObj: {
                    error: message,
                    isError: false,
                    show: true,
                }
            }
            ))
        }
    },

    fetchCountryCodes: async () => {
        try {
            const { data } = await AxiosInstance.get(`${Config.baseUrl}/country-code/`)
            set((state: any) => ({ ...state, error: "", countryCodes: data }))
            return data
        } catch (err: any) {
            let message = 'Cannot fetch country codes';
            if (Array.isArray(err.response.data.message)) {
                message = err.response.data.message[0]
            } else {
                message = err.response.data.message
            }
            set((state: any) => ({
                ...state, errAlertObj: {
                    error: message,
                    isError: false,
                    show: true,
                }
            }
            ))
        }
    },

    fetchCountryList: async () => {
        try {
            const { data } = await AxiosInstance.get(`${Config.baseUrl}/country-code/selectCountriesOptions`)
            set((state: any) => ({ ...state, error: "", countryOptions: data }))
            return data
        } catch (err: any) {
            let message = 'Cannot fetch country codes';
            if (Array.isArray(err.response.data.message)) {
                message = err.response.data.message[0]
            } else {
                message = err.response.data.message
            }
            set((state: any) => ({
                ...state, errAlertObj: {
                    error: message,
                    isError: false,
                    show: true,
                }
            }
            ))
        }
    },

    fetchCityList: async (nameValue, params) => {
        try {
            const { status, data } = await AxiosInstance.post(`${Config.baseUrl}/country-code/getCityFromCountry?searchFilter={"name":"${nameValue}"}&limit=50&offset=0`, params)
            return { statusCode: status, data: data }
        } catch (err: any) {
            let message = 'Cannot fetch country codes';
            if (Array.isArray(err.response.data.message)) {
                message = err.response.data.message[0]
            } else {
                message = err.response.data.message
            }
            return { statusCode: 400, data: [] }
        }
    },

}))
