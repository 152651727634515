import { useStore } from "../store/store";
import {
  Group,
  Card,
  Badge,
  Button,
  Text,
  useMantineTheme,
  SimpleGrid,
  Image,
  LoadingOverlay,
} from "@mantine/core";
import useErrandListStore from "../store/errand-list.store";
import moment from "moment";
import { useEffect, useState } from "react";
import { useModals } from "@mantine/modals";
import { useNavigate } from "react-router-dom";
import { useErrandStore } from "../store/errand-store";
import Config from "../config/config";
import axios from "axios";

function IncompleteErrandList(props: any) {
  const { enums, setParcelDetails, setEnums } = useStore();
  const { fetchErrandDetails } = useErrandStore();
  const [loading, setLoading] = useState(true);
  const theme = useMantineTheme();
  const { errandList, deleteErrand, fetchErrandList } = useErrandListStore();
  let navigate = useNavigate();
  const [isEmpty, setIsEmpty] = useState(true);
  useEffect(() => {
    const init = async () => {
      let { data, status } = await axios.get(`${Config.baseUrl}/globalEnums`);
      if (status === 200) {
        // console.log(data);
        setEnums(data);
      }
      await fetchErrandList([1]);
      setLoading(false);
      console.log(errandList);
    };
    init();
  }, []);
  const modals = useModals();
  const openDeleteModal = (id: number) => {
    modals.openConfirmModal({
      title: "Remove this Errand",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you do not wish to finish submitting this errand?
        </Text>
      ),
      labels: {
        confirm: "Remove Errand",
        cancel: "No don’t remove it",
      },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => {
        deleteErrand(id);
      },
    });
  };
  return (
    <div className="w-100" style={{ height: "100vh", overflowY: "scroll" }}>
      <LoadingOverlay visible={loading} />
      <SimpleGrid spacing="xs" className="w-100 px-5">
        {errandList && enums && errandList.length ? (
          errandList.map((errand) => {
            if (errand.status === enums.ERRAND_STATUS.INITIATED.value) {
              return (
                <Card shadow="sm" p="lg">
                  <Group
                    position="apart"
                    style={{ marginBottom: 5, marginTop: theme.spacing.sm }}
                  >
                    <Text weight={600}>{errand.deliveryType.name}</Text>
                    <Group>
                      <Badge
                        variant="gradient"
                        gradient={{ from: "#170052", to: "#4E3882" }}
                      >
                        {errand.errandUniqueId}
                      </Badge>
                      <Badge
                        variant="gradient"
                        gradient={{ from: "#e33960", to: "#e33980" }}
                      >
                        {enums.ERRAND_STATUS.INITIATED.label}
                      </Badge>
                    </Group>
                  </Group>
                  {errand.pickUpLocation && errand.deliveryLocation ? (
                    <table className="display-sm-none">
                      <tr className="px-5">
                        <td>
                          <Text
                            weight={500}
                            size="sm"
                            style={{ lineHeight: 1.5 }}
                          >
                            Pick up from
                          </Text>
                        </td>
                        <td className="px-5">
                          <Text size="sm" style={{ lineHeight: 1.5 }}>
                            {errand.pickUpLocation.street +
                              "," +
                              errand.pickUpLocation.postcode}
                          </Text>
                        </td>
                        {/* </tr>
                    <tr> */}
                        <td>
                          <Text
                            weight={500}
                            size="sm"
                            style={{ lineHeight: 1.5 }}
                          >
                            Pickup Date
                          </Text>
                        </td>
                        <td style={{ marginLeft: "5px" }}>
                          <Text size="sm" style={{ lineHeight: 1.5 }}>
                            {moment(errand.pickUpTime).format(
                              "ddd, MM D YYYY, h:mm a"
                            )}
                          </Text>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Text
                            weight={500}
                            size="sm"
                            style={{ lineHeight: 1.5 }}
                          >
                            Deliver To
                          </Text>
                        </td>
                        <td className="px-5">
                          <Text size="sm" style={{ lineHeight: 1.5 }}>
                            {errand.deliveryLocation.street +
                              "," +
                              errand.deliveryLocation.postcode}
                          </Text>
                        </td>
                        <td>
                          <Text
                            weight={500}
                            size="sm"
                            style={{ lineHeight: 1.5 }}
                          >
                            Delivery Date
                          </Text>
                        </td>
                        <td style={{ marginLeft: "5px" }}>
                          <Text size="sm" style={{ lineHeight: 1.5 }}>
                            {moment(errand.deliveryTime).format(
                              "ddd, MM D YYYY, h:mm a"
                            )}
                          </Text>
                        </td>
                      </tr>
                    </table>
                  ) : (
                    <></>
                  )}

                  <Group position="right">
                    <Button
                      variant="light"
                      color="red"
                      style={{ marginTop: 14 }}
                      onClick={() => {
                        openDeleteModal(errand.id);
                      }}
                    >
                      Remove
                    </Button>
                    <Button
                      variant="light"
                      color="blue"
                      style={{ marginTop: 14 }}
                      onClick={async () => {
                        localStorage.setItem(
                          "errandUniqueId",
                          errand.errandUniqueId
                        );
                        await fetchErrandDetails(errand.errandUniqueId);
                        navigate("/myerrand/create-errand/select-type");
                      }}
                    >
                      Continue
                    </Button>
                  </Group>
                </Card>
              );
            }
          })
        ) : (
          <></>
        )}
        {!errandList.length && !loading ? (
          <Text
            size="xl"
            weight={700}
            style={{ textAlign: "center" }}
            className={"text-primary"}
          >
            You Haven't Posted Any Errands Yet.
          </Text>
        ) : (
          <></>
        )}
      </SimpleGrid>
    </div>
  );
}

export default IncompleteErrandList;
