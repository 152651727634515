import { useStore } from "../store/store";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useErrandStore } from "../store/errand-store";
import { Stepper } from "@mantine/core";
var stylingObject = {
  mainDiv: {
    width: "20%",
    paddingLeft: "16px",
  }
};
function StepNav() {
  const { errandDetails } = useErrandStore();
  const { createErrandNav } = useStore();
  let navigate = useNavigate();
  return (
    <>
      <div className="step-nav" style={stylingObject.mainDiv}>
        <ul className="list-unstyled">
          <li
            className={`side-nav-custom ${errandDetails.stepsCompleted === 0 || createErrandNav === 1
              ? "active"
              : ""
              }`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              navigate("./select-type");
            }}
          >
            1. Select Type
          </li>
          <li
            className={`${errandDetails.stepsCompleted === 1 ||
              errandDetails.stepsCompleted === 2
              ? ""
              : "disabled"
              } side-nav-custom ${createErrandNav === 2 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              navigate("./delivery-details");
            }}
          >
            2. Delivery Details
          </li>
          <li
            className={`${errandDetails.stepsCompleted === 2 ? "" : "disabled"
              } side-nav-custom ${createErrandNav === 3 ? "active" : ""}`}
            onClick={() => {
              navigate("./parcel-details");
            }}
          >
            3. Parcel Details
          </li>
        </ul>
      </div>
      <div className="stepper-wrapper m-3">
        <Stepper active={createErrandNav-1} orientation="horizontal" onStepClick={(stepIndex) => {
          if (stepIndex == 0) {
            navigate("./select-type")
          } else if (stepIndex == 1) {
            navigate("./delivery-details")
          } else {
            navigate("./parcel-details")
          }
        }}>
          <Stepper.Step>
          </Stepper.Step>
          <Stepper.Step allowStepSelect={
            (errandDetails.stepsCompleted === 1 ||
              errandDetails.stepsCompleted === 2)
          }>
          </Stepper.Step>
          <Stepper.Step allowStepSelect={errandDetails.stepsCompleted === 2}>
          </Stepper.Step>
        </Stepper>
      </div>
    </>
  );
}

export default StepNav;
