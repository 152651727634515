import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import deliveryDetails from "./delivery-details";
import parcelDetails from "./parcel-details";
import { useErrandStore } from "../store/errand-store";
import {
  Badge,
  Button,
  Card,
  Divider,
  Group,
  Input,
  Text,
  Title,
  Box,
  TextInput,
  Loader,
  Center,
} from "@mantine/core";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { CustomMap } from "./custom-map.component";
import { PaymentModal } from "./payment-modal";
import { showNotification } from "@mantine/notifications";
import { useModals } from "@mantine/modals";
import moment from "moment";
import { DatePicker } from "@mantine/dates";
import DateTimePicker from "react-datetime-picker";
import { Check, Cross, X } from "tabler-icons-react";

function Summary() {
  const { resetErrandStore } = useErrandStore();
  const ref = useRef<HTMLDivElement>(null);
  const [map, setMap] = useState<any>();
  const [promoCode, setPromoCode] = useState("");
  const { enums, setParcelDetails } = useStore();
  const modals = useModals();
  const [cardDetails, setCardDetails] = useState<any>({
    date: moment(),
  });
  useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}));
    }
  }, [ref, map]);
  // Payment Details Capture Modal
  const openPaymentDetailsCaptureModal = () =>
    modals.openConfirmModal({
      title: "Add to Payment Method",
      centered: true,
      children: (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                placeholder="Enter Your Card Number"
                onChange={(event) => {
                  let obj = cardDetails;
                  obj["number"] = event.target.value;
                  setCardDetails(obj);
                }}
                label="Card Number"
                required
              />
            </div>
            <div className="form-group col-6">
              <label className="payment-date-label">Expiry date</label>
              <DateTimePicker
                onChange={(data: any) => {
                  let obj = cardDetails;
                  console.log(data);
                  obj["date"] = data;
                  setCardDetails(obj);
                }}
                calendarClassName={"d-none"}
                clearIcon={null}
                format={"MM/yy"}
                yearPlaceholder={"--"}
                calendarIcon={<></>}
                className="w-100 payment-date-input"
              ></DateTimePicker>
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                placeholder="Enter Your CVC"
                onChange={(event) => {
                  let obj = cardDetails;
                  obj["cvc"] = event.target.value;
                  setCardDetails(obj);
                }}
                label="CVC"
                required
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                autoComplete="on"
                name="cc-name"
                placeholder="Enter Holder Name"
                onChange={(event) => {
                  let obj = cardDetails;
                  obj["holderName"] = event.target.value;
                  setCardDetails(obj);
                }}
                label="Holder Name"
                required
              />
            </div>
          </div>
        </>
      ),
      labels: { confirm: "Add Payment Method and Pay", cancel: "Cancel" },
      confirmProps: { className: "bg-danger" },
      onCancel: () => console.log("Cancel"),
      onConfirm: () => {
        Object.keys(cardDetails).forEach((key) => {
          if (!cardDetails[key]) {
            return false;
          }
        });
        let year = moment(cardDetails.date).format("YY");
        let month = moment(cardDetails.date).format("MM");
        let date = moment(month + "/20" + year, "MM/yy").toDate();
        if (moment(date).isBefore(moment())) {
          showNotification({
            id: "Date",
            disallowClose: true,
            autoClose: 5000,
            title: "Invalid Expiry Date",
            message: "",
            color: "red",
            loading: false,
          });
          modals.closeAll();
        }
        // add card to stripe and our database
        saveCard();
      },
    });

  const openPaymentProcessingModal = () => {
    const id = modals.openModal({
      title: "Processing your payment",
      centered: true,
      closeOnClickOutside: false,
      closeOnEscape: false,
      withCloseButton: false,
      children: (
        <>
          <Center>
            <Loader />
          </Center>
        </>
      ),
    });
  };
  const saveCard = async () => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/payments/addCard/`,
        {
          number: cardDetails.number,
          expMonth: parseInt(moment(cardDetails.date).format("M")),
          expYear: moment(cardDetails.date).get("y"),
          holderName: cardDetails.holderName,
          cvc: cardDetails.cvc,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data) {
        proceedToPayment();
        showNotification({
          id: "payment-not-success",
          disallowClose: true,
          autoClose: 2000,
          title: "Payment",
          message: "Payment Method Successfully Added",
          color: "green",
          loading: false,
        });
      }
    } catch (err: any) {
      showNotification({
        id: "payment-not-success",
        disallowClose: true,
        autoClose: 2000,
        title: "Payment",
        message: "Unable to add payment",
        color: "red",
        loading: false,
      });
    }
  };

  // call summary of current order
  let navigate = useNavigate();

  const [order, setOrder] = useState({} as any);
  const openInfoModal = (title: string, text: string) =>
    modals.openModal({
      title: title,
      centered: true,
      children: <Text size="sm">{text}</Text>,
    });
  const proceedToPayment = async () => {
    try {
      let { data, status } = await axios.get(
        `${Config.baseUrl}/errand/isDefaultPaymentExist`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (!data.defaultPaymentExist) {
        showNotification({
          id: "payment-success",
          disallowClose: true,
          autoClose: 2000,
          title: "Payment",
          message: "Default Payment Does not exist.",
          color: "red",
          loading: false,
        });
        //open Payment Capture modal
        openPaymentDetailsCaptureModal();
      } else {
        openPaymentProcessingModal();
        let { data, status } = await axios.post(
          `${Config.baseUrl}/payments/capturePayment`,
          {
            errandUniqueId: order.errandDetails.errandUniqueId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (data) {
          modals.closeAll();
          showNotification({
            id: "payment-success",
            disallowClose: true,
            autoClose: 2000,
            title: "Payment",
            message: "Payment done successfully",
            color: "green",
            loading: false,
          });
          setParcelDetails({
            postageDetails: "",
            shoppingList: "",
            additionalDetails: "",
            weight: null,
            size: null,
          });
          resetErrandStore();
          navigate("/myerrand/open-errand/upcoming");
        }
      }
    } catch (err) {
      modals.closeAll();
      showNotification({
        id: "payment-not-success",
        disallowClose: true,
        autoClose: 2000,
        title: "Payment",
        message: "Payment not succeeded",
        color: "red",
        loading: false,
      });
    }
  };

  const applyPromoCode = async () => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/errand/applyPromoCode`,
        {
          promoCode: promoCode,
          errandUniqueId: order.errandDetails.errandUniqueId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data) {
        setOrder(data);
      }
    } catch (error: any) {
      showNotification({
        id: "promo-code",
        disallowClose: true,
        autoClose: 2500,
        title: "Promo Code",
        message: error.response.data.message,
        color: "red",
        loading: false,
      });
    }
  };

  const removePromoCode = async () => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/errand/removePromoCode`,
        {
          promoCode: promoCode,
          errandUniqueId: order.errandDetails.errandUniqueId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data) {
        setOrder(data);
        setPromoCode("");
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    let getErrandSummary = async () => {
      let uid = localStorage.getItem("errandUniqueId");
      let { data, status } = await axios.get(
        `${Config.baseUrl}/errand/errandSummary`,
        { params: { uniqueId: uid } }
      );
      if (data) {
        setOrder(data);
        setPromoCode(data.errandDetails.coupon.name);
      }
    };
    getErrandSummary();
  }, []);

  return (
    <div className="summary-wrapper">
      <div
        className="map"
        style={{
          backgroundColor: "white",
          height: "100vh",
          width: "100%",
          position: "absolute",
          top: "60px",
        }}
      >
        {order &&
        order.errandDetails &&
        order.errandDetails.pickUpLocation &&
        order.errandDetails.deliveryLocation ? (
          <CustomMap
            pickLatLng={{
              lat: parseFloat(order.errandDetails.pickUpLocation.lat),
              lng: parseFloat(order.errandDetails.pickUpLocation.long),
            }}
            dropLatLng={{
              lat: parseFloat(order.errandDetails.deliveryLocation.lat),
              lng: parseFloat(order.errandDetails.deliveryLocation.long),
            }}
          ></CustomMap>
        ) : (
          <></>
        )}
      </div>
      <Card shadow="sm" p="lg" style={{ margin: 15 }}>
        <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
          <Title className="text-blue text-pink" order={2}>
            Errand Summary
          </Title>
          <Group position="apart" style={{ marginBottom: 5, marginTop: 5 }}>
            <Badge
              variant="gradient"
              gradient={{ from: "#170052", to: "#4E3882" }}
            >
              {order && order.errandDetails
                ? order.errandDetails.errandUniqueId
                : ""}
            </Badge>
            <Badge variant="gradient" gradient={{ from: "orange", to: "red" }}>
              {order && order.errandDetails
                ? enums.ERRAND_PRIORITY[
                    Object.keys(enums.ERRAND_PRIORITY).find(
                      (key) =>
                        enums.ERRAND_PRIORITY[key].value ===
                        order.errandDetails.priority
                    ) || "STANDARD"
                  ].label
                : "STANDARD"}
            </Badge>
          </Group>
        </Group>
        <Divider my="sm" />
        <Group position="apart">
          <table style={{ width: "100%" }}>
            {order.displayData ? (
              Object.keys(order.displayData).map((key) => {
                if (key !== "finalPrice") {
                  return (
                    <>
                      <tr
                        style={{
                          borderBottom: "0.5px solid #f4f2f8",
                          height: "10px",
                        }}
                      >
                        <td style={{ width: "40%" }}>
                          <Text
                            component="span"
                            align="center"
                            size="md"
                            className="text-light-blue"
                            weight={700}
                          >
                            {order.displayData[key]["keyLabel"]}
                          </Text>
                        </td>
                        <td>
                          <div>
                            {key === "additionalDetails" ||
                            key === "postageDetails" ||
                            key === "shoppingList" ? (
                              <Text
                                // styles={{
                                //   span: {
                                //     display: "inline-block",
                                //   },
                                // }}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  openInfoModal(
                                    order.displayData[key]["keyLabel"],
                                    order.displayData[key]["valueLabel"]
                                  );
                                }}
                                align="left"
                                lineClamp={2}
                                component="span"
                                size="md"
                                weight={300}
                                className="text-light-blue mx-4"
                              >
                                {order.displayData[key]["valueLabel"]}
                              </Text>
                            ) : (
                              <Text
                                // styles={{
                                //   span: {
                                //     display: "inline-block",
                                //   },
                                // }}
                                style={{
                                  display: "inline-block",
                                }}
                                align="left"
                                component="span"
                                size="md"
                                weight={300}
                                className="text-light-blue mx-4"
                              >
                                {order.displayData[key]["valueLabel"]}
                              </Text>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                }
              })
            ) : (
              <></>
            )}
            <tr>
              <td>
                <Text
                  component="span"
                  align="center"
                  size="md"
                  className="text-light-blue"
                  weight={700}
                  style={{ fontFamily: "Greycliff CF, sans-serif" }}
                >
                  Promo Code
                </Text>
              </td>
              <tr>
                <td>
                  <div id="promo" className="my-2 d-flex gap-2">
                    <Input
                      id="promo"
                      placeholder="Promo Code"
                      rightSection={
                        <X
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            // REMOVE PROMO CODE
                            removePromoCode();
                          }}
                        />
                      }
                      value={promoCode}
                      onChange={(event: any) => {
                        setPromoCode(event.target.value);
                      }}
                    />
                    <Button
                      styles={{
                        root: {
                          backgroundColor: "#4E3882",
                        },
                      }}
                      onClick={() => {
                        applyPromoCode();
                      }}
                    >
                      <Check />
                    </Button>
                  </div>
                </td>
              </tr>
            </tr>
            {(order && order.displayData && order.displayData.finalPrice) ||
            (order && order.displayData && order.displayData.price) ? (
              <>
                <tr>
                  <td colSpan={2}>
                    <Box
                      className="final-errand-fee-box"
                      sx={(theme) => ({
                        backgroundColor:
                          theme.colorScheme === "dark"
                            ? theme.colors.dark[6]
                            : theme.colors.gray[0],
                        margin: "10px auto;",
                        textAlign: "center",
                        padding: "10px",
                        borderRadius: theme.radius.md,
                        border: "2px solid #e33960",
                      })}
                    >
                      <Text
                        component="span"
                        align="center"
                        size="xl"
                        className="text-light-blue"
                        weight={700}
                      >
                        £{" "}
                        {order &&
                        order.displayData &&
                        order.displayData.finalPrice
                          ? order.displayData.finalPrice.value
                          : order.displayData.price.value}
                      </Text>
                      <br />
                      <Text
                        component="span"
                        align="center"
                        size="md"
                        className="text-light-blue w-100"
                        weight={300}
                      >
                        Final Errand Fee
                      </Text>
                      <Text
                        component="p"
                        align="center"
                        className="text-light-blue"
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        (excl. VAT)
                      </Text>
                    </Box>
                  </td>
                </tr>
              </>
            ) : (
              <></>
            )}
          </table>
        </Group>
        <Button
          className="bg-danger"
          onClick={() => {
            proceedToPayment();
          }}
          style={{
            marginTop: 14,
            float: "right",
            width: "50%",
            height: "2.9rem",
          }}
        >
          <Text
            component="span"
            align="center"
            size="xl"
            className="text-white"
            weight={700}
            style={{ fontFamily: "Greycliff CF, sans-serif" }}
          >
            Pay Now
          </Text>
        </Button>
      </Card>
    </div>
  );
}

export default Summary;
