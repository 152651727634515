import create from "zustand";
import _, { omit } from 'underscore';
import AxiosInstance from "../utils/axios-instance";
import Config from "../config/config";


const initialState = {
}

export const useMemberStore = create<any>((set: any) => ({
    fetchTransportList: async () => {
        try {
            const { status, data } = await AxiosInstance.get(`${Config.baseUrl}/transport`);
            return { statusCode: status, data };
        } catch (err: any) {
            let message = 'Cannot get transport list';
            if (err.response) {
                if (Array.isArray(err.response.data.message)) {
                    message = err.response.data.message[0]
                } else {
                    message = err.response.data.message
                }
            }
            return { statusCode: 400, data: { message: message } };
        }
    },
}))
