import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { PencilFill } from "react-bootstrap-icons";
import _ from "underscore";
import Config from "../config/config";
import moment from "moment";
import { Form } from "react-bootstrap";
import { SimpleGrid, TextInput } from "@mantine/core";

function Profile() {
  const { setErrorMessage } = useStore();
  const [userData, setUserData] = useState<any>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState('');
  const [disable, setDisable] = useState(true);
  useEffect(() => {
    const getUser = async () => {
      try {
        // initiate userData
        let { data, status } = await axios.get(
          `${Config.baseUrl}/users/currentUserDetails`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setContactNumber(data.contactNumber);
        setEmail(data.email);
        setGender(data.gender);
        setDob(moment(data.dateOfBirth, 'YYYY-MM-DD').format('DD/MM/YYYY'));
      } catch (err: any) {
        setErrorMessage(err.response.data.message[0]);
      }
    };
    getUser();
  }, []);
  const onSave = async () => {
    try {
      let updatedUser = await axios.put(`${Config.baseUrl}/users/${1}`, {
        email,
        contactNumber,
        firstName,
        lastName,
      });
    } catch (err: any) {
      console.log(err);
      setErrorMessage(err.response.data.message[0]);
    }
    setDisable(true);
  };
  return (
    <div className="m-3 w-100">
      <SimpleGrid cols={2} spacing={'sm'}>
        <TextInput
          placeholder="First Name"
          defaultValue={firstName || ""}
          value={firstName || ""}
          label="First Name"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
        <TextInput
          placeholder="Last Name"
          defaultValue={lastName || ""}
          value={lastName || ""}
          label="Last Name"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
        <TextInput
          placeholder="Contact Number"
          defaultValue={contactNumber || ""}
          value={contactNumber || ""}
          label="Contact Number"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
        <TextInput
          placeholder="Email"
          defaultValue={email || ""}
          value={email || ""}
          label="Email"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
        <TextInput
          placeholder="Gender"
          defaultValue={gender || ""}
          value={gender || ""}
          label="Gender"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
        <TextInput
          placeholder="Date Of Birth"
          defaultValue={dob || ""}
          value={dob || ""}
          label="Date Of Birth"
          classNames={{
            label: 'text-primary font-weight-bold'
          }}
          disabled={true}
          required
        />
      </SimpleGrid>
    </div>
  );
}

export default Profile;
