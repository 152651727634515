import Config from "../config/config";
import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
var stylingObject = {
  mainDiv: {
    width: "20%",
  },
};
function AccountNav() {
  let navigate = useNavigate();
  let { setOrders } = useStore();
  const [accountNav, setAccountNav] = useState(1);
  async function doNavigate(path: number) {
    switch (path) {
      case 1:
        navigate("profile");
        break;
      case 2:
        navigate("business-details");
        break;
      case 3:
        navigate("customer-list");
        break;
      case 4:
        navigate("payment-details");
        break;
      case 5:
        navigate("invoice");
        break;
      case 6:
        navigate("subscriptions");
        break;

      default:
        break;
    }
    setAccountNav(path);
  }
  return (
    <>
      <div style={stylingObject.mainDiv} className='step-nav'>
        <ul className="list-unstyled">
          <li
            className={`side-nav-custom ${accountNav === 1 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(1);
            }}
          >
            Profile
          </li>
          <li
            className={`side-nav-custom ${accountNav === 2 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(2);
            }}
          >
            Business Details
          </li>
          <li
            className={`side-nav-custom ${accountNav === 3 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(3);
            }}
          >
            Customer List
          </li>
          <li
            className={`side-nav-custom ${accountNav === 4 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(4);
            }}
          >
            Payment Details
          </li>
          <li
            className={`side-nav-custom ${accountNav === 5 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(5);
            }}
          >
            Invoices
          </li>
          <li
            className={`side-nav-custom ${accountNav === 5 ? "active" : ""}`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              doNavigate(6);
            }}
          >
            Subscriptions
          </li>
          <li
            className={`side-nav-custom`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              window.open(
                'https://myerrand.co.uk/terms-of-use',
                '_blank'
              );
            }}
          >
            Terms and Conditions
          </li>
          <li
            className={`side-nav-custom`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              window.open(
                'https://myerrand.co.uk/privacy-notice/',
                '_blank'
              );
            }}
          >
            Privacy Policy
          </li>
          <li
            className={`side-nav-custom`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              window.open(
                'http://myerrand.co.uk/',
                '_blank'
              );
            }}
          >
            Help
          </li>
          <li
            className={`side-nav-custom`}
            style={{ marginBottom: "5px" }}
            onClick={() => {
              navigate("/");
              localStorage.setItem('token', '')
            }}
          >
            Logout
          </li>
        </ul>
      </div>
    </>
  );
}

export default AccountNav;
