import { Route, Routes, useLocation } from "react-router-dom";
import Home from "./home.component";
import logoLight from '../assets/images/logo-light.png'
import CreateErrand from "./create-errand";
import OpenErrand from "./open-errand";
import { useEffect, useState } from "react";
import Account from "./account.component";
import { useNavigate } from "react-router-dom";
import TeamMember from "./Team-Members/team-member.component";
function Nav({notLogged}:any) {
    let navigate = useNavigate();
    const [nav, setNav] = useState(1);
    const location = useLocation()
    useEffect(()=>{
        let path = location.pathname.substring(location.pathname.split('/', 2).join('/').length+1,location.pathname.lastIndexOf('/') );
        if(path === 'create-errand'){
            setNav(1)
        }else if(path === 'open-errand'){
            setNav(2)
        }else if(path === 'completed'){
            setNav(3)
        } else if(path === 'account'){
            setNav(4)
        } else if(path === 'internal-team'){
            setNav(5)
        }
    },[location])
    return (
        <>
            <div className='bg-primary d-flex top-nav' style={{ height: '70px', padding: '10px', alignItems: 'end' }}>
                <img src={logoLight} height='50' style={{ marginRight: 'auto', marginLeft: '20px' }} onClick={()=>{window.open('http://www.myerrand.co.uk', '_blank')}} className={'cursor-pointer'} />
            </div>
            {
            !notLogged? 
            <Routes>
                <Route path='create-errand/*' element={<CreateErrand />} />
                <Route path='open-errand/*' element={<OpenErrand />} />
                <Route path='account/*' element={<Account />} />
                <Route path='internal-team/*' element={<TeamMember />} />
            </Routes>:<></>
            }
        </>
    );
}

export default Nav;
