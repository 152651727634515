import { useStore } from "../store/store";
import axios from "axios";
import { useEffect, useState } from "react";
import { PencilFill, Save } from "react-bootstrap-icons";
import Config from "../config/config";
import { Badge, Button, SimpleGrid, TextInput } from "@mantine/core";
function BusinessDetails() {
  const { setErrorMessage, enums } = useStore();
  const [businessData, setBusinessData] = useState<any>({});
  const [disable, setDisable] = useState(true);
  const onSave = async () => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/business/updateBusinessById`,
        businessData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (data.success) {
        setErrorMessage("Business was successfully updated");
      }
    } catch (err: any) {
      console.log(err);
      setErrorMessage(err.response.data.message[0]);
    }
    setDisable(true);
  };
  useEffect(() => {
    const getBusiness = async () => {
      try {
        let { data, status } = await axios.get(
          `${Config.baseUrl}/business/fetchBusinessByUserId`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        let address = data.businessAddress[0];
        delete data.businessAddress;
        setBusinessData({ ...data, address });
      } catch (error: any) {
        setErrorMessage(error.response.data.message[0]);
      }
    };
    // initializing react component
    getBusiness();
  }, []);
  return (
    <>
      <div className="m-md-4 m-sm-0 w-100">
        <Badge
          variant="gradient"
          gradient={{ from: "orange", to: "red" }}
          style={{
            position: "relative",
            right: "-100%",
            transform: "translateX(-100%)",
          }}
        >
          {enums && businessData
            ? businessData.businessType === enums.BUSINESS_TYPE.SOLE.value
              ? enums.BUSINESS_TYPE.SOLE.label
              : enums.BUSINESS_TYPE.LIMITED_COMPANY.label
            : ""}
        </Badge>
        <SimpleGrid cols={2} spacing={'sm'} className='mt-3'>
          <TextInput
            placeholder="Business Name"
            defaultValue={businessData ? businessData.name || "" : ""}
            value={businessData ? businessData.name || "" : ""}
            label="Business Name"
            classNames={{
              label: 'text-primary font-weight-bold'
            }}
            disabled={disable}
            required
          />
          {/* If business type is pvt lmtd company we need to show registration number */}
          {enums && enums.BUSINESS_TYPE && businessData ? (
            businessData.businessType !== enums.BUSINESS_TYPE.SOLE.value ? (
              <TextInput
                placeholder="Company Number"
                defaultValue={businessData ? businessData.registrationNumber || "" : ""}
                value={businessData ? businessData.registrationNumber || "" : ""}
                label="Company Number"
                classNames={{
                  label: 'text-primary font-weight-bold'
                }}
                onChange={(event) => {
                  let obj = businessData;
                  obj["registrationNumber"] = event.target.value;
                  setBusinessData({ ...obj });
                }}
                disabled={disable}
                required
              />
            ) : (
              <></>
            )
          ) : (
            false
          )}
          <TextInput
            placeholder="Street And Number"
            value={
              businessData && businessData.address
                ? businessData.address.street || ""
                : ""
            }
            defaultValue={
              businessData && businessData.address
                ? businessData.address.street || ""
                : ""
            }
            label="Street And Number"
            classNames={{
              label: 'text-primary font-weight-bold'
            }}
            onChange={(event) => {
              let obj = businessData;
              obj["address"]["street"] = event.target.value;
              setBusinessData({ ...obj });
            }}
            disabled={disable}
            required
          />
          <TextInput
            placeholder="Building Name"
            value={
              businessData && businessData.address
                ? businessData.address.buildingName || ""
                : ""
            }
            defaultValue={
              businessData && businessData.address
                ? businessData.address.buildingName || ""
                : ""
            }
            label="Building Name"
            classNames={{
              label: 'text-primary font-weight-bold'
            }}
            onChange={(event) => {
              let obj = businessData;
              obj["address"]["buildingName"] = event.target.value;
              setBusinessData({ ...obj });
            }}
            disabled={disable}
          />
          <TextInput
            placeholder="Postcode"
            value={
              businessData && businessData.address
                ? businessData.address.postcode || ""
                : ""
            }
            defaultValue={
              businessData && businessData.address
                ? businessData.address.postcode || ""
                : ""
            }
            label="Postcode"
            classNames={{
              label: 'text-primary font-weight-bold'
            }}
            onChange={(event) => {
              let obj = businessData;
              obj["address"]["postcode"] = event.target.value;
              setBusinessData({ ...obj });
            }}
            disabled={disable}
            required
          />
          <TextInput
            placeholder="City"
            value={
              businessData && businessData.address
                ? businessData.address.city || ""
                : ""
            }
            defaultValue={
              businessData && businessData.address
                ? businessData.address.city || ""
                : ""
            }
            label="City"
            classNames={{
              label: 'text-primary font-weight-bold'
            }}
            onChange={(event) => {
              let obj = businessData;
              obj["address"]["city"] = event.target.value;
              setBusinessData({ ...obj });
            }}
            disabled={disable}
            required
          />
        </SimpleGrid>
        <div className="w-100 my-3">
          <label id="city" className="primary" style={{ fontSize: "16px" }}>
            Which industry best describes your company?
          </label>
          <select
            className={`form-control primary`}
            disabled={disable}
            value={businessData ? businessData.companyDescription : ""}
            onChange={(event) => {
              let obj = businessData;
              obj["companyDescription"] = event.target.value;
              setBusinessData({ ...obj });
            }}
          >
            <option value="">Select Description</option>
            {enums && enums.COMPANY_DESCRIPTION ? (
              Object.keys(enums.COMPANY_DESCRIPTION).map((key) => {
                return (
                  <option value={enums.COMPANY_DESCRIPTION[key].value}>
                    {enums.COMPANY_DESCRIPTION[key].label}
                  </option>
                );
              })
            ) : (
              <></>
            )}
          </select>
        </div>
        <div></div>
        {enums &&
          businessData &&
          enums.COMPANY_DESCRIPTION &&
          enums.COMPANY_DESCRIPTION["OTHER"] &&
          businessData.companyDescription ==
          enums.COMPANY_DESCRIPTION["OTHER"].value ? (
          <div className="w-100 my-3">
            <TextInput
              placeholder="Other"
              value={
                businessData && businessData.otherBusinessDescription
                  ? businessData.otherBusinessDescription || ""
                  : ""
              }
              defaultValue={
                businessData && businessData.otherBusinessDescription
                  ? businessData.otherBusinessDescription || ""
                  : ""
              }
              label="Other"
              classNames={{
                label: 'text-primary font-weight-bold'
              }}
              onChange={(event) => {
                let obj = businessData;
                obj["otherBusinessDescription"] = event.target.value;
                setBusinessData({ ...obj });
              }}
              disabled={disable}
              required
            />
          </div>
        ) : (
          <></>
        )}
        <div className="w-100">
          <label className="primary" style={{ fontSize: "16px" }}>
            Approximate errands per day?
          </label>
          <div className={`row d-flex justify-content-even`}>
            {enums && enums.EXPECTED_ERRANDS && businessData ? (
              Object.keys(enums.EXPECTED_ERRANDS).map((key) => {
                return (
                  <div className="col-3 mt-2">
                    <input
                      id={enums.EXPECTED_ERRANDS[key].value}
                      value={enums.EXPECTED_ERRANDS[key].value}
                      checked={
                        enums && businessData
                          ? businessData.dailyExpectedErrands ===
                            enums.EXPECTED_ERRANDS[key].value
                            ? true
                            : false
                          : false
                      }
                      className={`opacity ${disable ? "disabled" : ""}`}
                      type="radio"
                      disabled={disable}
                      name="expectedErrands"
                      onChange={(event) => {
                        let obj = businessData;
                        obj["dailyExpectedErrands"] = event.target.value;
                        setBusinessData({ ...obj });
                      }}
                    ></input>
                    <label
                      htmlFor={enums.EXPECTED_ERRANDS[key].value}
                      className={`radio-label w-100  ${disable ? "disabled" : ""
                        }`}
                      style={{ fontSize: "16px", textAlign: "center" }}
                    >
                      {enums.EXPECTED_ERRANDS[key].label}
                    </label>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row">
          <div className="d-flex mt-4">
            <Button
              onClick={() => {
                setDisable(false);
              }}
              className={`${disable ? 'bg-primary' : ''}`}
              style={{
                marginLeft: 'auto'

              }}
              disabled={!disable}
              leftIcon={<PencilFill className={`${disable ? '' : 'text-white'}`} size={14} />}>
              Edit
            </Button>
            <Button
              onClick={() => {
                onSave();
              }}
              className={`${!disable ? 'bg-danger' : ''}`}
              style={{
                marginLeft: '10px'

              }}
              disabled={disable}
              leftIcon={<Save className={`${!disable ? 'text-white' : ''}`} size={14} />}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default BusinessDetails;
