import { useErrandStore } from "../store/errand-store";
import { Upload, Photo, X, Icon as TablerIcon } from "tabler-icons-react";
import { useStore } from "../store/store";
import { MantineTheme, Group, Text, useMantineTheme, Title, SimpleGrid , CloseButton, Image} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { useEffect, useState } from "react";

function LocalShopping() {
  let { setShoppingList, shoppingList } = useStore();
  const [files, setFiles] = useState<any[]>([]);

  const { updateLocalPostageImages } = useErrandStore()
  // useEffect(()=>{
  //   updateLocalPostageImages(files)
  // },[files])

  useEffect(()=>{
    updateLocalPostageImages([...files])
  },[files])

  useEffect(() => {
      let timer = setTimeout(() => {
      updateLocalPostageImages(files)
    }, 1000);

    return () => clearTimeout(timer)
  }, [files]);

  const theme = useMantineTheme();
  return (
    <>
      <Title className='text-primary my-3' order={4}>Local Shopping Details</Title>
      <div className="row">
        <div>
          <Text size="sm" weight={600} className="text-primary">What items do you need ?</Text>
          <Text size="xs" className="text-primary">
            Please provide a list of all items you would like us to purchase on
            your behalf.
          </Text>
          <textarea
            style={{ fontSize: "16px" }}
            className="montserrat primary my-3 parcel-details-text-area"
            placeholder="Enter Your Shopping List"
            value={shoppingList}
            onChange={(event) => {
              setShoppingList(event.target.value);
            }}
            name="shopping-list"
          ></textarea>
        </div>
        <div>
          <Text size="sm" weight={600} className="text-primary">Approximate cost of shopping</Text>
          <Text size="xs" className="text-primary">
            The maximum amount you are able to spend on local shopping is £30.
            PLEASE NOTE: the partner completing the errand will be asked not
            to exceed the amount stated.
          </Text>
        </div>
      </div>
      
      <div className="my-3 parcel-details-text-area">
        {/* <Dropzone
          onDrop={(files) => {
            let fileArray = localPostageImagesArray.push(files[0]);
            updateLocalPostageImages(fileArray);
          }}
          onReject={(files) => console.log("rejected files", files)}
          maxSize={10000000}
          accept={IMAGE_MIME_TYPE}
        >
          {(status) => dropzoneChildren(status, theme)}
        </Dropzone> */}

      <Title className="text-primary my-3" order={4}>
        Upload Images
      </Title>
      <div className="w-100">
        <div>
          <Dropzone
            accept={IMAGE_MIME_TYPE}
            onDrop={(file) => {
              setFiles([...files, file]);
            }}
          >
            <Text align="center">Drop images here</Text>
          </Dropzone>

          <SimpleGrid
            cols={4}
            breakpoints={[{ maxWidth: "sm", cols: 1 }]}
            mt={files.length > 0 ? "xl" : 0}
          >
            {files.map((file, index) => {
              return (
                <Group className="position-relative">
                  <CloseButton
                    className="image-close"
                    aria-label="Close modal"
                    onClick={() => {
                      let updateFileArray = files.filter((e, i) => {
                        if (index == i) {
                          return false;
                        } else {
                          return true;
                        }
                      });
                      setFiles(updateFileArray);
                    }}
                  />
                  <Image
                    width={"100%"}
                    height={150}
                    key={index}
                    src={URL.createObjectURL(new Blob(file))}
                    imageProps={{
                      onLoad: () =>
                        URL.revokeObjectURL(
                          URL.createObjectURL(new Blob(file))
                        ),
                    }}
                  />
                </Group>
              );
            })}
          </SimpleGrid>
        </div>
      </div>
      </div>
    </>
  );
}

export default LocalShopping;

// const dropzoneChildren = (
//   status: DropzoneStatus,
//   theme: MantineTheme
// ) => {
//   const { localPostageImagesArray, updateLocalPostageImages } = useErrandStore();
//   function getIconColor(status: DropzoneStatus, theme: MantineTheme) {
//     return status.accepted
//       ? theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
//       : status.rejected
//         ? theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
//         : theme.colorScheme === "dark"
//           ? theme.colors.dark[0]
//           : theme.colors.gray[7];
//   }

//   function ImageUploadIcon({
//     status,
//     ...props
//   }: React.ComponentProps<TablerIcon> & { status: DropzoneStatus }) {
//     if (status.accepted) {
//       return <Upload {...props} />;
//     }

//     if (status.rejected) {
//       return <X {...props} />;
//     }

//     return <Photo {...props} />;
//   }
//   return (
//     <Group
//       position="center"
//       spacing="xl"
//       style={{ minHeight: 220, pointerEvents: "none" }}
//     >
//       <ImageUploadIcon
//         status={status}
//         style={{ color: getIconColor(status, theme) }}
//         size={80}
//       />

//       <div>
//         <Text size="xl" inline>
//           Please upload a screenshot of your desired items.
//           {localPostageImagesArray.length === 1 ? (
//             <Text size="sm" color="dimmed" inline mt={7}>
//               You have uploaded {localPostageImagesArray.length} image.
//             </Text>
//           ) : (
//             <Text size="sm" color="dimmed" inline mt={7}>
//               You have uploaded {localPostageImagesArray.length} images.
//             </Text>
//           )}
//         </Text>
//       </div>
//     </Group>
//   );
// };
