import { useStore } from "../../store/store";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { PencilFill, Search } from "react-bootstrap-icons";
import _ from "underscore";
import Config from "../../config/config";
import moment from "moment";
import { Form } from "react-bootstrap";
import { Autocomplete, Select, SimpleGrid, TextInput } from "@mantine/core";
import { debounce } from "./comman-utils";
import { useMemberStore } from "../../store/team-member-store";
import { useGlobalEnumsStore } from "../../store/global-enums-store";

function CreateTeamMember() {
  const { fetchTransportList } = useMemberStore();
  const { fetchCountryCodes, fetchGlobalEnums, countryCodes } = useGlobalEnumsStore()
  const [teamMemberParams, setTeamMemberParams] = useState<any>()
  const [citySearchValue, setCitySearchValue] = useState("");
  const [storeSearchValue, setStoreSearchValue] = useState("");
  const [selectedGender, setSelectedGender] = useState('NonSelected');
  const [selectedVehicle, setSelectedVehicle] = useState<any>();
  const [cityList, setCityList] = useState<any>([])
  const [storeList, setStoreList] = useState<any>([])
  const [vehicleTypes, setVehicleTypes] = useState<any>([])
  const [vehicleData, setVehicleData] = useState<any>([])
  const [isRegNumber, setIsRegNumber] = useState<any>()
  const [disabled, setDisabled] = useState(true);
  const [countryCodeList, setCountryCodeList] = useState<any>([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);

  const handleSearchCity = useCallback(debounce((selectedCountry, inputVal) => searchCity(selectedCountry, inputVal), 1000), []);
  const searchCity = async (selectedCountry, searchText: any) => {
    const ids = [selectedCountry.id]
    console.log('searchText=>', searchText);
    console.log('ids=>', ids);
    if (searchText.length > 0 && ids.length > 0) {
      // const { statusCode, data } = await fetchCityList(searchText, { countryCodes: ids.join(',') })
      // console.log('getCityList=>', data[0]);

      // if (statusCode == 200 || statusCode == 201) {
      //     setCityList(data[0].map(city => { return ({ value: city.id, label: city.displayName }) }));
      // } else {
      //     setCityList([]);
      // }
    } else {
      setCityList([]);
    }
  }

  useEffect(() => {
    const getPageData = async () => {
      if (countryCodes && countryCodes.length > 0) {
        // setCountryCodeList(countryCodes)
        setCountry(countryCodes)
      } else {
        const countryCodes = await fetchCountryCodes();
        // setCountryCodeList(countryCodes)
        setCountry(countryCodes)
      }
    }
    getPageData()
    fetchVehicleList()
  }, [])


  const setCountry = (data) => {
    const primary = data.filter((country: any) => country.iso2 == 'GB' || country.iso2 == 'MA' || country.iso2 == 'TZ');
    console.log('primary=>', primary);
    const UK: any = primary.filter((country: any) => country.iso2 == 'GB')[0]
    primary.unshift(primary.splice(primary.findIndex((item: any) => item.id === UK.id), 1)[0])
    const others = data.filter((country: any) => country.iso2 != 'GB' || country.iso2 != 'MA' || country.iso2 != 'TZ');
    setCountryCodeList([
      ...primary,
      ...others
    ]);
  }

  useEffect(() => {
    console.log('selectedVehicle=>', selectedVehicle);
    console.log('VEHICLE=>', vehicleData.filter(vehicle => vehicle.id == selectedVehicle));
    if (selectedVehicle) {
      setIsRegNumber(vehicleData.filter(vehicle => vehicle.id == selectedVehicle)[0]?.registrationRequired)
    }
  }, [selectedVehicle])

  const fetchVehicleList = async () => {
    const { statusCode, data } = await fetchTransportList();
    if (statusCode == 200 || statusCode == 201) {
      setVehicleTypes(data.map((transport: any) => {
        return ({ value: transport.id, label: transport.name })
      }))
      setVehicleData(data);
    }
  }

  return (
    <div className="m-3 w-100">

      <div className="row">
        <SimpleGrid className="my-3" cols={2} spacing={"md"}>
          <TextInput
            placeholder="First Name"
            width={50}
            onChange={(event: any) => {
              setTeamMemberParams({
                ...teamMemberParams,
                firstName: event.target.value,
              });
            }}
            label="First Name"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
          />
          <TextInput
            placeholder="Last Name"
            onChange={(event: any) => {
              setTeamMemberParams({
                ...teamMemberParams, lastName: event.target.value
              });
            }}
            label="Last Name"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
            required
          />
          <TextInput
            placeholder="Email"
            onChange={(event: any) => {
              setTeamMemberParams({
                ...teamMemberParams,
                email: event.target.value,
              });
            }}
            required
            label="Email"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <div className="row">
            <div className="col" style={{ flex: '0.6' }}>
              <Select
                data={countryCodeList.map(code => { return ({ value: code.id, label: `(+${code.dial}) ${code.name} ` }) })}
                onChange={(_value) => {
                  var countryCodeObj = countryCodeList.filter((i) => i.id == _value)[0]
                  console.log(countryCodeObj);
                  setTeamMemberParams({
                    ...teamMemberParams,
                    countryCode: countryCodeObj.countryCode,
                    dialCode: countryCodeObj.dial,
                  });
                }}
                placeholder="Country"
                label=" "
                classNames={{
                  label: "text-primary font-weight-bold w-50",
                }}
              />
            </div>
            <div className="col">
              <TextInput
                placeholder="Mobile Number"
                onChange={(event: any) => {
                  setTeamMemberParams({
                    ...teamMemberParams, contactNumber: event.target.value
                  });
                }}
                required
                maxLength={50}
                label="Mobile Number"
                classNames={{
                  label: "text-primary font-weight-bold",
                }}
              ></TextInput>
            </div>
          </div>
          <Autocomplete
            styles={{
              dropdown: {
                height: "15rem",
                overflowY: "scroll",
              },
            }}
            value={citySearchValue}
            placeholder="Search and select city here"
            label="City"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
            icon={<Search size={16} />}
            limit={100}
            required
            filter={(value, item) => true} // to get all the data
            onChange={(value) => {
              handleSearchCity('', value);
              setCitySearchValue(value);
            }}
            onItemSubmit={(value) => {
              setCitySearchValue(value.id);
            }}
            data={cityList}
          />
          <Select
            data={[
              { value: 'Male', label: 'Male' },
              { value: 'Female', label: 'Female' },
              { value: 'Other', label: 'Other' },
              { value: 'NonSelected', label: 'Non Selected' },
            ]}
            onChange={(_value) => {
              setTeamMemberParams({
                ...teamMemberParams,
                gender: _value,
              });
              setSelectedGender(_value || '')
            }}
            placeholder="Select Gender"
            label="Gender"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <Select
            data={vehicleTypes}
            onChange={(_value) => {
              setTeamMemberParams({
                ...teamMemberParams,
                vehicle: _value,
              });
              setSelectedVehicle(_value || '')
            }}
            label="Vehicle"
            placeholder="Select vehicle"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
          <TextInput
            placeholder="Vehicle Registration Number"
            onChange={(event: any) => {
              setTeamMemberParams({
                ...teamMemberParams, vehicleRegNumber: event.target.value
              });
            }}
            required
            disabled={!isRegNumber}
            maxLength={50}
            label="Vehicle Registration Number"
            classNames={{
              label: "text-primary font-weight-bold",
            }}
          ></TextInput>
          <Form.Group controlId="dob">
            <Form.Label className="primary" style={{ fontSize: "14px", paddingBottom: '0px', marginBottom: '3px' }}>
              Date Of Birth
            </Form.Label>
            <Form.Control
              className="primary primary-border mt-0"
              style={{ height: '35px' }}
              type="date"
              name="dob"
              placeholder="Date of Birth"
              max={moment().subtract(18, "years").format("YYYY-MM-DD")}
              onChange={(event: any) => {
                setTeamMemberParams({
                  ...teamMemberParams,
                  dob: event.target.value,
                });
              }}
            />
          </Form.Group>
          <Autocomplete
            styles={{
              dropdown: {
                height: "15rem",
                overflowY: "scroll",
              },
            }}
            value={storeSearchValue}
            placeholder="Search and select store here"
            icon={<Search size={16} />}
            limit={100}
            required
            filter={(value, item) => true} // to get all the data
            onChange={(value) => {
              setStoreSearchValue(value);
            }}
            onItemSubmit={(value) => {
              setStoreSearchValue(value.id);
            }}
            data={storeList}
            label="Store"
            classNames={{
              label: "text-primary font-weight-bold w-50",
            }}
          />
        </SimpleGrid>
      </div>

      <div className="row mt-1">
        <div className="col-4 offset-8 p-0">
          <button
            className={`btn-primary text-decoration-none w-50 ${disabled ? "disabled" : ""
              }`}
            style={{
              textAlign: "center",
              padding: "10px",
              width: "30%",
              float: "right",
            }}
            onClick={() => {
              // updateErrandDetails();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateTeamMember;
