import DataTable from "react-data-table-component";
import { useEffect } from "react";
import useInvoiceStore from "../store/invoice.store";
import { Download } from "tabler-icons-react";
import Config from "../config/config";
import AxiosInstance from "../utils/axios-instance";

function Invoice() {
  const {
    invoiceList,
    fetchInvoiceList,
  } = useInvoiceStore();

  useEffect(() => {
    fetchInvoiceList();
  }, []);

  function handleDownloadDoc(fileName: string, blobData: any) {
    // Downloads the file
    const fileURL = window.URL.createObjectURL(new Blob([blobData]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", fileName);
    fileLink.setAttribute("target", "_blank");
    document.body.appendChild(fileLink);
    fileLink.click();
    fileLink.remove();
  }

  const columns = [
    
    {
      name: "File Name",
      selector: (row: any) => row.file.originalFileName,
    },
    {
      name: "Month",
      selector: (row: any) => row.month,
    },
    {
      name: "Invoice Id",
      selector: (row: any) => row.id,
    },
    {
      name: "Download",
      cell: (row: any) => (
        <div>
          <Download
          onClick={async () => {
            // download file
            let response = await AxiosInstance.get(
              `${Config.baseUrl}/user-invoice-mapping/download/${row.id}`,
              { responseType: "blob" }
            );
            handleDownloadDoc(
              row.file.originalFileName,
              response.data
            );
          }}
        ></Download>
        </div>
      ),
    }
  ];

  return (
    <div className="w-100" style={{ height: "500px", overflowY: "scroll" }}>
      {invoiceList.length ? (
        <DataTable columns={columns} data={invoiceList} />
      ) : (
        <></>
      )}
    </div>
  );
}

export default Invoice;
