import { Title, Text } from "@mantine/core";
import { ExclamationDiamondFill } from "react-bootstrap-icons";
import { useStore } from "../store/store";

function Postage() {
  let { setPostageDetails, postageDetails } = useStore();
  return (
    <div className="parcel-details-text-area">
      <Title className="text-primary my-3" order={4}>
        Post Office Run Details
      </Title>
      <Text size="sm" className="text-primary my-3">
        Please provide details on the method of postage required. Eg, recorded,
        trackable, first class, etc.
      </Text>
      <Text size="sm" className="text-primary my-3">
        <ExclamationDiamondFill
          size={"17"}
          className={"text-ember mx-3"}
        ></ExclamationDiamondFill>
        Make sure you write or print the shipping address onto the package.
      </Text>
      <textarea
        className="w-100 primary"
        placeholder="Postage Details"
        value={postageDetails}
        onChange={(event: any) => {
          setPostageDetails(event.target.value);
        }}
        name="postageDetails"
      ></textarea>
      <Text
        size="sm"
        className="text-primary my-3"
        style={{ fontSize: "1rem" }}
      >
        <ExclamationDiamondFill
          size={"17"}
          className={"text-ember mx-3"}
          style={{ marginBottom: "5px" }}
        ></ExclamationDiamondFill>
        The holding charge for the postage is £15. This covers any uncertain
        postage costs, and we’ll only take the amount to cover the final cost of
        postage. If you expect postage to be more than £15, please contact our
        support team.
      </Text>
    </div>
  );
}

export default Postage;
