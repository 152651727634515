
import create from "zustand";
import _, { omit } from 'underscore';
import axios from "axios";
import Config from "../config/config";
import { showNotification } from "@mantine/notifications";
import moment from "moment";

const MINUTE_DIFF = 30;

export const useErrandStore = create<any>((set: any) => ({
  clickAndCollectImagesArray: [],
  localPostageImagesArray: [],
  errandDetails: {
    pickUp: {
      street: '',
      datetime: moment().add(30, 'minutes').toDate(),
      postcode: '',
      lat: '',
      long: '',
      buildingName: ''
    },
    drop: {
      street: '',
      datetime: moment().add(1, 'minutes').toDate(),
      postcode: '',
      lat: '',
      long: '',
      buildingName: ''
    },
    stepsCompleted: 0
  },
  fetchErrandDetails: async (errandUniqueId: string) => {
    set((state: any) => ({ ...state, loading: true }))
    try {
      const { data } = await axios.get(`${Config.baseUrl}/errand/${errandUniqueId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({
        ...state, errandDetails: {
          selectType: data.deliveryType.id,
          errandUniqueId: data.errandUniqueId,
          pickUp: {
            street: data.pickUpLocation ? data.pickUpLocation.street : '',
            datetime: moment().add(MINUTE_DIFF, 'minutes').toDate(),
            postcode: data.pickUpLocation ? data.pickUpLocation.postcode : '',
            lat: data.pickUpLocation ? data.pickUpLocation.lat : '',
            long: data.pickUpLocation ? data.pickUpLocation.long : '',
            buildingName: data.pickUpLocation ? data.pickUpLocation.buildingName : '',
            flat: data.pickUpLocation ? data.pickUpLocation.flat : '',
            name: data.pickUpLocation ? data.senderName : ""
          },
          drop: {
            street: data.deliveryLocation ? data.deliveryLocation.street : "",
            datetime: moment().add(MINUTE_DIFF * 2, 'minutes').toDate(),
            postcode: data.deliveryLocation ? data.deliveryLocation.postcode : "",
            lat: data.deliveryLocation ? data.deliveryLocation.lat : "",
            long: data.deliveryLocation ? data.deliveryLocation.long : "",
            buildingName: data.deliveryLocation ? data.deliveryLocation.buildingName : "",
            flat: data.deliveryLocation ? data.deliveryLocation.flat : "",
            name: data.deliveryLocation ? data.receiverName : ""
          }
        }
      }))
    } catch (error: any) {
      // showNotification({
      //   id: "Fetch-Errand-Details",
      //   disallowClose: true,
      //   autoClose: 2000,
      //   title: "Errand",
      //   message: Array.isArray(error.response.data.message) ? error.response.data.message[0] : error.response.data.message,
      //   color: "red",
      //   loading: false,
      // });
    }
  },
  updateDeliveryDetails: async (pickUp, deliverTo, errandUniqueId) => {
    try {
      const { data } = await axios.post(`${Config.baseUrl}/errand/updateErrand`, {
        pickUpLocation: {
          id: pickUp.id || null,
          street: pickUp.street,
          flat: pickUp.flat,
          senderContactNumber: pickUp.senderContactNumber,
          name: pickUp.name,
          datetime: pickUp.datetime,
          postcode: pickUp.postcode,
          lat: pickUp.lat,
          long: pickUp.long
        },
        deliveryLocation: {
          id: deliverTo.id || null,
          street: deliverTo.street,
          flat: deliverTo.flat,
          receiverContactNumber: deliverTo.receiverContactNumber,
          name: deliverTo.name,
          datetime: deliverTo.datetime,
          postcode: deliverTo.postcode,
          lat: deliverTo.lat,
          long: deliverTo.long
        },
        errandUniqueId: errandUniqueId,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({
        ...state, errandDetails: _.extend(state.errandDetails, {
          pickUp: {
            id: data.pickUpLocation.id,
            street: data.pickUpLocation.street,
            datetime: moment().add(MINUTE_DIFF, 'minutes').toDate(),
            postcode: data.pickUpLocation.postcode,
            lat: data.pickUpLocation.lat,
            long: data.pickUpLocation.long,
            senderContactNumber: data.pickUpLocation.senderContactNumber,
            flat: data.pickUpLocation.flat,
            name: data.senderName
          },
          drop: {
            id: data.deliveryLocation.id,
            street: data.deliveryLocation.street,
            datetime: moment().add(MINUTE_DIFF * 2, 'minutes').toDate(),
            postcode: data.deliveryLocation.postcode,
            lat: data.deliveryLocation.lat,
            long: data.deliveryLocation.long,
            receiverContactNumber: data.deliveryLocation.receiverContactNumber,
            flat: data.deliveryLocation.flat,
            name: data.receiverName
          }, stepsCompleted: 2
        })
      }))
      if (data) {
        showNotification({
          id: "errand-delivery-details-update",
          disallowClose: true,
          autoClose: 2000,
          title: "Errand",
          message: 'Delivery Details Updated Successfully',
          color: "green",
          loading: false,
        })
      }
      return true
    } catch (error: any) {
      // show notifications
      showNotification({
        id: "Errand-Select-Type",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: Array.isArray(error.response.data.message) ? error.response.data.message[0] : error.response.data.message,
        color: "red",
        loading: false,
      });
      return false
    }
  },
  updateSelectType: async (deliveryType: number, errandUniqueId: string) => {
    try {
      let { data, status } = await axios.post(`${Config.baseUrl}/errand/updateErrand`, {
        errandUniqueId: errandUniqueId,
        deliveryType: deliveryType
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, errandDetails: _.extend(state.errandDetails, { selectType: data.deliveryType.id, stepsCompleted: 1 }) }))
      showNotification({
        id: "errand-select-type-update",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: 'Select Type Updated Successfully',
        color: "green",
        loading: false,
      })
    } catch (error: any) {
      // show notifications
      showNotification({
        id: "Errand-Select-Type",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: Array.isArray(error.response.data.message) ? error.response.data.message[0] : error.response.data.message,
        color: "red",
        loading: false,
      });
      throw error
    }
  },
  createErrand: async (deliveryType: number) => {
    try {
      let { data, status } = await axios.post(`${Config.baseUrl}/errand/createErrand`, {
        deliveryType: deliveryType
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
      )
      localStorage.setItem("errandUniqueId", data.errandUniqueId);
      set((state: any) => ({
        ...state, errandDetails: {
          selectType: data.deliveryType.id, errandUniqueId: data.errandUniqueId, stepsCompleted: 1, pickUp: {
            street: '',
            datetime: moment().add(MINUTE_DIFF, 'minutes').toDate(),
            postcode: '',
            lat: '',
            long: '',
            buildingName: ''
          },
          drop: {
            street: '',
            datetime: moment().add(MINUTE_DIFF * 2, 'minutes').toDate(),
            postcode: '',
            lat: '',
            long: '',
            buildingName: ''
          }
        }
      }))
      showNotification({
        id: "errand-create",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: 'Errand Created Successfully',
        color: "green",
        loading: false,
      })
      return true
    } catch (err: any) {
      // show notifications
      showNotification({
        id: "errand-error",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: err.response.message ? err.response.message : err.message,
        color: "red",
        loading: false,
      })
    }
  },
  setErrandSelectType: async (typeId: number) => {
    set((state: any) => ({
      ...state, errandDetails: {
        ...state.errandDetails,
        selectType: typeId
      }
    }))
  },
  updateParcelDetails: async (errandUniqueId) => {
    try {
      const { data } = await axios.post(`${Config.baseUrl}/errand/updateErrand`, {
        errandUniqueId: errandUniqueId,
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({
        ...state, errandDetails: _.extend(state.errandDetails, {
          stepsCompleted: 2
        })
      }))
      if (data) {
        showNotification({
          id: "errand-delivery-details-update",
          disallowClose: true,
          autoClose: 2000,
          title: "Errand",
          message: 'Parcel Details Updated Successfully',
          color: "green",
          loading: false,
        })
      }
      return true
    } catch (error: any) {
      // show notifications
      showNotification({
        id: "Errand-Select-Type",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: Array.isArray(error.response.data.message) ? error.response.data.message[0] : error.response.data.message,
        color: "red",
        loading: false,
      });
      return false
    }
  },
  updateClickAndCollectImages: async (files: any) => {
    set((state: any) => ({
      ...state, clickAndCollectImagesArray: files
    }))
  },
  updateLocalPostageImages: async (files: any) => {
    console.log(files)
    set((state: any) => ({
      ...state, localPostageImagesArray: files
    }))
  },
  resetErrandStore: async () => {
    localStorage.setItem('errandUniqueId', '');
    set((state: any) => ({
      ...state, errandDetails: {
        pickUp: {
          street: '',
          datetime: moment().add(MINUTE_DIFF, 'minutes').toDate(),
          postcode: '',
          lat: '',
          long: '',
          buildingName: ''
        },
        drop: {
          street: '',
          datetime: moment().add(MINUTE_DIFF * 2, 'minutes').toDate(),
          postcode: '',
          lat: '',
          long: '',
          buildingName: ''
        },
        stepsCompleted: 0
      },
      clickAndCollectImagesArray: [],
      localPostageImagesArray: []
    }))
  }
}))
