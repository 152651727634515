import create from "zustand"
import axios from 'axios';
import { showNotification } from "@mantine/notifications";
import moment from "moment";
import Config from "../config/config";


const initialState = {
  errandList: [],
  loading: false,
  error: "",
}

const useErrandListStore = create<any>((set: any) => ({
  errandList: initialState.errandList,
  loading: initialState.loading,
  error: initialState.error,

  resetErrandList: ()=>{
    set((state: any) => ({ ...state, error: "", errandList: [] }))
  },
  fetchErrandList: async (status:Array<number>) => {
    set((state: any) => ({ ...state, loading: true }))
    try {
      const { data } = await axios.get(`${Config.baseUrl}/errand/fetchClientErrands?status=${status}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      })
      set((state: any) => ({ ...state, error: "", errandList: data }))
    } catch (error) {
      showNotification({
        id: "contact",
        disallowClose: true,
        autoClose: 2000,
        title: "Cannot fetch Contact details",
        message: "",
        color: "red",
        loading: false,
      });
    }
  },

  addToCustomerList: async (customer: any) => {
    // update shift
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/contacts`,
        {
          ...customer,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      set((state: any) => ({ ...state, customerList: data.contactUsers }))
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 2000,
        title: "Customer",
        message: "Customer Added successfully",
        color: "red",
        loading: false,
      })
    } catch (err: any) {
      let message = 'Cannot add the shift';
      if (!err.response.data.error) {
        if (Array.isArray(err.response.data.message)) {
          message = err.response.data.message[0]
        } else {
          message = err.response.data.message
        }
      } else {
        message = err.response.data.error
      }
      showNotification({
        id: "Customer",
        disallowClose: true,
        autoClose: 2000,
        title: "Customer",
        message: message,
        color: "red",
        loading: false,
      })
    }
  },
  deleteErrand: async (id: number) => {
    try {
      let { data, status } = await axios.delete(
        `${Config.baseUrl}/errand/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      });
      set((state: any) => ({ ...state, errandList: state.errandList.filter((e: any) => e.id !== id) }))
      showNotification({
        id: "contact",
        disallowClose: true,
        autoClose: 1000,
        title: "Errand",
        message: 'Errand Details were removed',
        color: "green",
        loading: false,
      })
    } catch (err) {
      showNotification({
        id: "login",
        disallowClose: true,
        autoClose: 2000,
        title: "Errand",
        message: "Errand was not removed",
        color: "red",
        loading: false,
      })
    }
  },
}))

export default useErrandListStore
