import { useStore } from "../store/store";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  PencilFill,
  Trash,
  InfoSquare,
  Plus,
  Calendar,
  PatchCheckFill,
} from "react-bootstrap-icons";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import DateTimePicker from "react-datetime-picker";
import Config from "../config/config";
import usePaymentStore from "../store/payment.store";
import { useModals } from "@mantine/modals";
import { TextInput, Text, Badge, LoadingOverlay } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { showNotification } from "@mantine/notifications";

function PaymentDetails() {
  const { setErrorMessage } = useStore();
  const modals = useModals();
  const [visible, setVisible] = useState(false);
  const {
    paymentList,
    fetchPaymentList,
    deletePayment,
    addPayment,
    defaultPayment,
  } = usePaymentStore();

  useEffect(() => {
    fetchPaymentList();
  }, []);

  const openAddModal = () => {
    let cardDetails: any = {};
    const id = modals.openConfirmModal({
      title: "Add Card",
      closeOnConfirm: false,
      closeOnCancel: false,
      children: (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                placeholder="Enter Your Card Number"
                onChange={(event) => {
                  cardDetails["number"] = event.target.value;
                }}
                label="Card Number"
                required
              />
            </div>
            <div className="form-group col-6">
              <label className="payment-date-label">Expiry date</label>
              <DateTimePicker
                onChange={(data: any) => {
                  cardDetails["date"] = data;
                }}
                calendarClassName={"d-none"}
                clearIcon={null}
                format={"MM/yy"}
                yearPlaceholder={"--"}
                calendarIcon={<></>}
                className="w-100 payment-date-input"
              ></DateTimePicker>
              {/* <DatePicker
                placeholder="Pick date"
                label=""
                inputFormat="MM/YYYY"
                labelFormat="MM/YYYY"
                defaultValue={moment().toDate()}
                value={cardDetails["date"]}
                onChange={(data: any) => {
                  console.log(data);
                  cardDetails["date"] = data;
                }}
              /> */}
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                autoComplete="on"
                name="cc-csc"
                placeholder="Enter Your CVC"
                onChange={(event) => {
                  cardDetails["cvc"] = event.target.value;
                }}
                label="CVC"
                required
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                autoComplete="on"
                name="cc-name"
                placeholder="Enter Holder Name"
                onChange={(event) => {
                  cardDetails["holderName"] = event.target.value;
                }}
                label="Holder Name"
                required
              />
            </div>
          </div>
        </>
      ),
      centered: true,
      labels: {
        confirm: "Add Card",
        cancel: "Cancel",
      },
      onCancel: () => {
        modals.closeAll();
      },
      onConfirm: async () => {
        let year = moment(cardDetails.date).format("YY");
        let month = moment(cardDetails.date).format("MM");
        let date = moment(month + "/20" + year, "MM/yy").toDate();
        if (moment(date).isBefore(moment())) {
          showNotification({
            id: "Date",
            disallowClose: true,
            autoClose: 5000,
            title: "Invalid Expiry Date",
            message: "",
            color: "red",
            loading: false,
          });
          modals.closeAll();
        }
        cardDetails.date = date;
        setVisible(true);
        await addPayment(cardDetails);
        await fetchPaymentList();
        setVisible(false);
        modals.closeAll();
      },
    });
  };

  const openMakeDefaultModal = (row: any) => {
    modals.openConfirmModal({
      title: "Make This Card As Default?",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to make this card as default?
        </Text>
      ),
      labels: {
        confirm: "Make Default",
        cancel: "Cancel",
      },
      confirmProps: { className: "bg-danger" },
      onCancel: () => {},
      onConfirm: async () => {
        await defaultPayment(row.id);
        await fetchPaymentList();
      },
    });
  };

  const openDeleteModal = (id: number) => {
    modals.openConfirmModal({
      title: "Delete this card",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this card details? This action is
          destructive and you will have to contact support to restore your data.
        </Text>
      ),
      labels: {
        confirm: "Remove Card Details",
        cancel: "No don't delete it",
      },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => {
        deletePayment(id);
      },
    });
  };

  const columns = [
    {
      name: "Brand",
      grow: 2,
      cell: (row: any) => {
        return (
          <div>
            {row.id !== 0 ? (
              row.isDefault ? (
                <>
                  <span className="m-0">{row.brand}</span>
                  <Badge
                    className="m-1"
                    variant="gradient"
                    gradient={{ from: "teal", to: "lime", deg: 105 }}
                  >
                    Default
                  </Badge>
                </>
              ) : (
                <span className="m-0">{row.brand}</span>
              )
            ) : (
              <></>
            )}
          </div>
        );
      },
      // sortable: true,
    },
    {
      name: "Exp Month",
      grow: 0.5,
      selector: (row: any) => row.exp_month,
      // sortable: true,
    },
    {
      name: "Exp Year",
      grow: 0.5,
      selector: (row: any) => row.exp_year,
      // sortable: true,
    },
    {
      name: "Ending in",
      grow: 1,
      cell: (row: any) =>
        row.id === 0 ? <div></div> : <div>**** **** **** {row.last4}</div>,
      // sortable: true,
    },
    {
      grow: 2,
      style: {
        padding: "0px",
      },
      name: "Delete",
      cell: (row: any) => (
        <div>
          <Trash
            className="text-pink"
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              openDeleteModal(row.id);
            }}
          ></Trash>
        </div>
      ),
    },
  ];
  return (
    <>
      <LoadingOverlay visible={visible}></LoadingOverlay>
      <div className="w-100 m-md-2">
        <DataTable
          columns={columns}
          data={paymentList}
          onRowClicked={(row, event) => {
            openMakeDefaultModal(row);
          }}
        />
      </div>
      <div className="float-btn m-5">
        <span onClick={() => openAddModal()}>
          <Plus className="text-white" size={"75"}></Plus>
        </span>
      </div>
    </>
  );
}

export default PaymentDetails;
