import DataTable from "react-data-table-component";
import Config from "../config/config";
import { Plus, PencilFill, Trash, InfoSquare } from "react-bootstrap-icons";

import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import axios from "axios";
import { url } from "inspector";
import _ from "underscore";
import { useStore } from "../store/store";
import { showNotification } from "@mantine/notifications";
import useCustomerListStore from "../store/customer-list.store";
import { TextInput, Button, Text, Select } from "@mantine/core";
import { useModals } from "@mantine/modals";
function CustomerList() {
  const [aModalShow, setAddModalShow] = useState(false);
  const [dModalShow, setDeleteModalShow] = useState(false);
  const [userId, setUserId] = useState<number>();
  // const { customerList, setErrorMessage, addCustomerList } = useStore();
  const modals = useModals();
  // const [customerDetails, setCustomerDetails] = useState<any>({});
  const {
    deleteCustomer,
    customerList,
    fetchCustomerList,
    addToCustomerList,
    updateCustomerList,
  } = useCustomerListStore();

  const openAddModal = () => {
    let customerDetails = {};
    const id = modals.openModal({
      title: "Add To Customer List",
      classNames:{
        modal: 'p-md-5 p-lg-5 p-sm-1'
      },
      children: (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                required
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    fName: event.target.value,
                  };
                }}
                label="First Name"
                placeholder="First Name"
                data-autofocus
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                label="Last Name"
                placeholder="Last Name"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    lName: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6 contact-number">
              <TextInput
                icon={
                  <TextInput
                    classNames={{ input: "p-1 wx-5" }}
                    value="+44"
                    disabled
                  />
                }
                iconWidth={45}
                label="Contact Number"
                placeholder="Contact Number"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    contactNumber: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                label="Email"
                placeholder="Email"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    email: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                required
                label="Street and number"
                placeholder="street"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    street: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                label="Flat No."
                placeholder="Flat"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    flat: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                required
                label="Postcode"
                placeholder="Postcode"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    postcode: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                label="Building Name"
                placeholder="Building Name"
                data-autofocus
                onChange={(event) => {
                  customerDetails = {
                    ...customerDetails,
                    building: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <Button
            style={{ float: "right", background: "#e33960" }}
            onClick={() => {
              console.log(customerDetails);
              addToCustomerList(customerDetails);
              modals.closeModal(id);
            }}
            mt="md"
          >
            Submit
          </Button>
        </>
      ),
    });
  };

  const openEditModal = async (contactUserId: number) => {
    let { data, status } = await axios.get(
      `${Config.baseUrl}/contactUser/${contactUserId}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    let updatedCustomerDetails = {};
    const id = modals.openModal({
      title: "Edit customer",
      classNames:{
        modal: 'p-sm-1'
      },
      children: (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                required
                defaultValue={data.firstName}
                onChange={(event) => {
                  data = {
                    ...data,
                    firstName: event.target.value,
                  };
                }}
                label="First Name"
                placeholder="First Name"
                data-autofocus
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                defaultValue={data.lastName}
                label="Last Name"
                placeholder="Last Name"
                data-autofocus
                onChange={(event) => {
                  data = {
                    ...data,
                    lastName: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6 contact-number">
              <TextInput
                defaultValue={data.contactNumber}
                label="Contact Number"
                placeholder="Contact Number"
                data-autofocus
                icon={
                  <TextInput
                    classNames={{ input: "p-1 wx-5" }}
                    value="+44"
                    disabled
                  />
                }
                iconWidth={45}
                onChange={(event) => {
                  data = {
                    ...data,
                    contactNumber: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                defaultValue={data.email}
                label="Email"
                placeholder="Email"
                data-autofocus
                onChange={(event) => {
                  data = {
                    ...data,
                    email: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                required
                label="Street and number"
                placeholder="street"
                data-autofocus
                defaultValue={data.contactLocations[0].street}
                onChange={(event) => {
                  data.contactLocations[0] = {
                    ...data.contactLocations[0],
                    street: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                label="Flat No."
                placeholder="Flat"
                data-autofocus
                defaultValue={data.contactLocations[0].flat}
                onChange={(event) => {
                  data.contactLocations[0] = {
                    ...data.contactLocations[0],
                    flat: event.target.value,
                  };
                }}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                required
                label="Postcode"
                placeholder="Postcode"
                data-autofocus
                defaultValue={data.contactLocations[0].postcode}
                onChange={(event) => {
                  data.contactLocations[0] = {
                    ...data.contactLocations[0],
                    postcode: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                label="Building Name"
                placeholder="Building Name"
                defaultValue={data.contactLocations[0].business}
                data-autofocus
                onChange={(event) => {
                  data.contactLocations[0] = {
                    ...data.contactLocations[0],
                    business: event.target.value,
                  };
                }}
              />
            </div>
          </div>
          <Button
            style={{ float: "right", background: "#e33960" }}
            onClick={() => {
              updateCustomerList(data);
              modals.closeModal(id);
            }}
            mt="md"
          >
            Update
          </Button>
        </>
      ),
    });
  };
  const openDeleteModal = (userId: number) => {
    modals.openConfirmModal({
      title: "Delete your profile",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this customer details? This action is
          destructive and you will have to contact support to restore your data.
        </Text>
      ),
      labels: {
        confirm: "Remove Customer Details",
        cancel: "No don't delete it",
      },
      confirmProps: { color: "red" },
      onCancel: () => {},
      onConfirm: () => {
        deleteCustomer(userId);
      },
    });
  };

  const openInfo = async (contactUserId: number) => {
    let { data, status } = await axios.get(
      `${Config.baseUrl}/contactUser/${contactUserId}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );
    const id = modals.openModal({
      title: "Customer Info",
      classNames:{
        modal: 'p-sm-1'
      },
      children: (
        <>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                disabled
                defaultValue={data.firstName}
                label="First Name"
                placeholder="First Name"
                data-autofocus
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                disabled
                defaultValue={data.lastName}
                label="Last Name"
                placeholder="Last Name"
                data-autofocus
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6 contact-number">
              <TextInput
                icon={
                  <TextInput
                    classNames={{ input: "p-1 wx-5" }}
                    value="+44"
                    disabled
                  />
                }
                iconWidth={45}
                disabled
                defaultValue={data.contactNumber}
                label="Contact Number"
                placeholder="Contact Number"
                data-autofocus
              />
            </div>
            <div className="form-group col-6">
              <TextInput
                disabled
                defaultValue={data.email}
                label="Email"
                placeholder="Email"
                data-autofocus
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                disabled
                label="Street and number"
                placeholder="street"
                data-autofocus
                defaultValue={data.contactLocations[0].street}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                disabled
                label="Flat No."
                placeholder="Flat"
                data-autofocus
                defaultValue={data.contactLocations[0].flat}
              />
            </div>
            <div className="form-group col-3">
              <TextInput
                disabled
                label="Postcode"
                placeholder="Postcode"
                data-autofocus
                defaultValue={data.contactLocations[0].postcode}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="form-group col-6">
              <TextInput
                disabled
                label="Building Name"
                placeholder="Building Name"
                defaultValue={data.contactLocations[0].business}
                data-autofocus
              />
            </div>
          </div>
        </>
      ),
    });
  };

  useEffect(() => {
    fetchCustomerList();
  }, []);

  const columns = [
    {
      name: "First Name",
      selector: (row: any) => row.firstName,
      // sortable: true,
    },
    {
      name: "Last Name",
      selector: (row: any) => row.lastName,
      // sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row: any) => row.contactNumber,
      // sortable: true,
    },
    {
      name: "Edit",
      cell: (row: any) => (
        <div>
          <PencilFill
            className="text-ember"
            size={20}
            onClick={() => {
              openEditModal(row.id);
            }}
          ></PencilFill>
        </div>
      ),
    },
    {
      name: "Delete",
      cell: (row: any) => (
        <div>
          <Trash
            className="text-pink"
            size={20}
            style={{ cursor: "pointer" }}
            onClick={() => {
              openDeleteModal(row.id);
            }}
          ></Trash>
        </div>
      ),
    },
    {
      name: "More Info",
      cell: (row: any) => (
        <div>
          <InfoSquare
            className="text-blue"
            size={20}
            onClick={() => {
              openInfo(row.id);
            }}
          ></InfoSquare>
        </div>
      ),
    },
  ];

  return (
    <div className="w-100" style={{ height: "500px", overflowY: "scroll" }}>
      {customerList.length ? (
        <DataTable columns={columns} data={customerList} />
      ) : (
        <></>
      )}
      <div className="float-btn m-5">
        <span onClick={() => openAddModal()}>
          <Plus className="text-white" size={"75"}></Plus>
        </span>
      </div>
    </div>
  );
}

export default CustomerList;
