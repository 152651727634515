import { GlobalEnums } from "@/store/global-enums";
import axios from "axios";
import { useEffect, useState } from "react";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../store/store";
import { ExclamationDiamondFill } from "react-bootstrap-icons";
import moment from "moment";
import Config from "../config/config";

function Register() {
  const [isValidCPassword, setIsValidCPassword] = useState(true);

  let { registerUser, setRegisterUser, enums } = useStore();

  useEffect(() => {
    if (
      registerUser.firstName &&
      registerUser.lastName &&
      registerUser.password &&
      registerUser.cPassword &&
      registerUser.dob &&
      registerUser.email &&
      registerUser.phoneNumber &&
      registerUser.gender &&
      isValidCPassword &&
      registerUser.isPrivacyPolicyChecked &&
      registerUser.isTocChecked &&
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(
        registerUser.password
      ) &&
      (registerUser.phoneNumber[0] !== "0" ||
        registerUser.phoneNumber[0] != 0) &&
      registerUser.phoneNumber.length === 12
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [
    registerUser.firstName,
    registerUser.lastName,
    registerUser.password,
    registerUser.cPassword,
    registerUser.dob,
    registerUser.email,
    registerUser.phoneNumber,
    registerUser.gender,
    isValidCPassword,
    registerUser.isPrivacyPolicyChecked,
    registerUser.isTocChecked,
  ]);

  const [disabled, setDisabled] = useState(true);

  let navigate = useNavigate();
  let { setErrorMessage } = useStore();

  const register = async () => {
    try {
      let { data, status } = await axios.post(
        `${Config.baseUrl}/users/verifyUserExist`,
        {
          email: registerUser.email.toLowerCase(),
          contactNumber: registerUser.phoneNumber.substring(2),
          role: enums.ROLES.CLIENT.value,
        }
      );
      if (
        !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(
          registerUser.password
        )
      ) {
        throw new Error("Invalid Password");
      }
      if (data.success === true) {
        navigate(`/register-business`);
      }
    } catch (error: any) {
      if (error.response.data) {
        setErrorMessage({
          error: error.response.data,
          isError: true,
          show: true,
        });
      }
    }
  };
  return (
    <>
      <div className="offset-6 col-6">
        <h3 className="text-primary text-center">Register with my Errand</h3>
        <div className="row">
          <div className="form-group col-6">
            <label
              id="firstName"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              First Name
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter First Name"
              name="firstName"
              value={
                registerUser && registerUser.firstName
                  ? registerUser.firstName
                  : ""
              }
              onChange={(event: any) => {
                setRegisterUser({
                  firstName: event.target.value,
                });
              }}
            ></input>
          </div>
          <div className="form-group col-6">
            <label
              id="lastName"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Last Name
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="Enter Last Name"
              name="lastName"
              value={
                registerUser && registerUser.lastName
                  ? registerUser.lastName
                  : ""
              }
              onChange={(event: any) => {
                setRegisterUser({
                  lastName: event.target.value,
                });
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col-6">
            <label
              id="phoneNumber"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Phone Number
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <div
                    id="button-tooltip"
                    className="text-primary bg-white"
                    style={{ width: "180px", padding: "10px" }}
                  >
                    Should be a Mobile number and must not include '0' at the beginning.
                  </div>
                }
              >
                <ExclamationDiamondFill
                  size={"17"}
                  className={"text-ember mx-1"}
                  style={{ marginBottom: "5px" }}
                ></ExclamationDiamondFill>
              </OverlayTrigger>
            </label>
            <div className="d-flex">
              <select className="primary select-code">
                <option>+44</option>
              </select>
              <input
                className="w-100 primary"
                type="text"
                placeholder="7824070381"
                name="phoneNumber"
                maxLength={10}
                value={
                  registerUser && registerUser.phoneNumber
                    ? registerUser.phoneNumber.substring(2)
                    : ""
                }
                onChange={(event: any) => {
                  setRegisterUser({
                    phoneNumber: "44" + event.target.value,
                  });
                }}
              ></input>
            </div>
            {registerUser.phoneNumber[2] == 0 &&
            registerUser.phoneNumber.length !== 0 ? (
              <span className="text-danger ">
                Should be a Mobile number and must not include '0' at the beginning.
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group col-6">
            <label id="email" className="primary" style={{ fontSize: "16px" }}>
              Email
            </label>
            <input
              className="w-100 primary"
              type="text"
              placeholder="test@gmail.com"
              name="email"
              value={
                registerUser && registerUser.email ? registerUser.email : ""
              }
              onChange={(event: any) => {
                setRegisterUser({
                  email: event.target.value.toLowerCase(),
                });
              }}
            ></input>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-group col-6">
            <label
              id="password"
              className="primary "
              style={{ fontSize: "16px" }}
            >
              Password
              <OverlayTrigger
                placement="right"
                delay={{ show: 250, hide: 400 }}
                overlay={
                  <div
                    id="button-tooltip"
                    className="text-primary bg-white"
                    style={{ width: "180px", padding: "10px" }}
                  >
                    Password must be Minimum eight characters, at least one
                    letter, one number and one special character
                  </div>
                }
              >
                <ExclamationDiamondFill
                  size={"17"}
                  className={"text-ember mx-1"}
                  style={{ marginBottom: "5px" }}
                ></ExclamationDiamondFill>
              </OverlayTrigger>
            </label>
            <input
              className="w-100 primary"
              type="password"
              placeholder="Enter Password"
              name="password"
              value={
                registerUser && registerUser.password
                  ? registerUser.password
                  : ""
              }
              onChange={(event: any) => {
                setRegisterUser({
                  password: event.target.value,
                });
                if (registerUser.cPassword !== event.target.value) {
                  setIsValidCPassword(false);
                } else {
                  setIsValidCPassword(true);
                }
              }}
            ></input>
            {!/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/gm.test(
              registerUser.password
            ) && registerUser.password.length > 0 ? (
              <span className="text-danger">
                Password must be a minimum of 8 characters, and must include a
                capital letter and a special character.
              </span>
            ) : (
              <></>
            )}
          </div>
          <div className="form-group col-6">
            <label
              id="cpassword"
              className="primary"
              style={{ fontSize: "16px" }}
            >
              Confirm Password
            </label>
            <input
              className="w-100 primary"
              type="password"
              placeholder="Enter Password"
              name="cpassword"
              value={
                registerUser && registerUser.cPassword
                  ? registerUser.cPassword
                  : ""
              }
              onChange={(event: any) => {
                setRegisterUser({
                  cPassword: event.target.value,
                });
                if (event.target.value !== registerUser.password) {
                  setIsValidCPassword(false);
                } else {
                  setIsValidCPassword(true);
                }
              }}
            ></input>
            {isValidCPassword === false ? (
              <span className="text-danger">Password and Confirm Password does not match</span>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-6">
            <Form.Group controlId="dob">
              <Form.Label className="primary" style={{ fontSize: "16px" }}>
                Date Of Birth
              </Form.Label>
              <Form.Control
                className="primary primary-border"
                type="date"
                name="dob"
                placeholder="Date of Birth"
                max={moment().subtract(18, "years").format("YYYY-MM-DD")}
                value={registerUser && registerUser.dob ? registerUser.dob : ""}
                onChange={(event: any) => {
                  setRegisterUser({
                    dob: event.target.value,
                  });
                }}
              />
            </Form.Group>
          </div>
          <div className="col-6">
            <label
              id="city"
              className="primary form-label"
              style={{ fontSize: "16px" }}
            >
              Gender
            </label>
            <select
              className="form-control primary"
              onChange={(event) => {
                setRegisterUser({
                  gender: event.target.value,
                });
              }}
              value={
                registerUser && registerUser.gender ? registerUser.gender : ""
              }
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
              <option value="NonSelected">Non Selected</option>
            </select>
          </div>
        </div>
        <div className="row mt-3">
          <div className="form-check col-6">
            <label
              className="form-check-label "
              style={{ fontSize: "14.5px", paddingLeft: "10px" }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                value=""
                checked={registerUser && registerUser.isTocChecked}
                onChange={() => {
                  setRegisterUser({
                    isTocChecked: !registerUser.isTocChecked,
                  });
                }}
              ></input>
              I agree to the My Errand{" "}
              <a href="https://myerrand.co.uk/terms-of-use/" target={"_blank"}>
                Terms & Conditions
              </a>
            </label>
          </div>
          <div className="form-check col-6 ">
            <label
              className="form-check-label text-primary"
              style={{ fontSize: "14px", paddingLeft: "10px" }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                value=""
                checked={registerUser && registerUser.isPrivacyPolicyChecked}
                onChange={() => {
                  setRegisterUser({
                    isPrivacyPolicyChecked:
                      !registerUser.isPrivacyPolicyChecked,
                  });
                }}
              ></input>
              I agree to the My Errand{" "}
              <a
                href="https://myerrand.co.uk/privacy-notice/"
                target={"_blank"}
              >
                Data & Privacy Policy
              </a>
            </label>
          </div>
        </div>
        <div className="d-flex">
          <button
            onClick={() => {
              register();
            }}
            className={`btn-primary text-decoration-none ${
              disabled ? "disabled" : ""
            }`}
            style={{ textAlign: "center", width: "25%", marginLeft: "auto" }}
          >
            Register
          </button>
        </div>
        <span
          className="align-self-end text-primary"
          style={{ fontSize: "14px" }}
        >
          Already have an account?{" "}
          <span style={{ cursor: "pointer" }}>
            <b>
              <Link className="text-danger text-decoration-none" to="/">
                Login
              </Link>
            </b>
          </span>
        </span>
      </div>
    </>
  );
}

export default Register;
